import React, {useEffect, useState} from "react";
import {SEO} from "../../../components/global/SEO";
import ReCAPTCHA from "react-google-recaptcha";
import {BHGInput, BHGInputWrapper} from "@bytehogs/design-system";
import Filter from "bad-words";
import {BytehogsAPI} from "../../../redux/actions/BytehogsAPI";
const filter = new Filter();
const personalEmailDomains = [
        "aol.com", "att.net", "comcast.net", "facebook.com", "gmail.com", "gmx.com", "googlemail.com",
        "google.com", "hotmail.com", "hotmail.co.uk", "mac.com", "me.com", "mail.com", "msn.com",
        "live.com", "sbcglobal.net", "verizon.net", "yahoo.com", "yahoo.co.uk",
        "email.com", "fastmail.fm", "games.com", "gmx.net", "hush.com", "hushmail.com", "icloud.com",
        "iname.com", "inbox.com", "lavabit.com", "love.com" , "outlook.com", "pobox.com", "protonmail.ch", "protonmail.com", "tutanota.de", "tutanota.com", "tutamail.com", "tuta.io",
        "keemail.me", "rocketmail.com", "safe-mail.net", "wow.com" , "ygm.com" ,
        "ymail.com", "zoho.com", "yandex.com",
        "bellsouth.net", "charter.net", "cox.net", "earthlink.net", "juno.com",
        "btinternet.com", "virginmedia.com", "blueyonder.co.uk", "freeserve.co.uk", "live.co.uk",
        "ntlworld.com", "o2.co.uk", "orange.net", "sky.com", "talktalk.co.uk", "tiscali.co.uk",
        "virgin.net", "wanadoo.co.uk", "bt.com",
        "sina.com", "sina.cn", "qq.com", "naver.com", "hanmail.net", "daum.net", "nate.com", "yahoo.co.jp", "yahoo.co.kr", "yahoo.co.id", "yahoo.co.in", "yahoo.com.sg", "yahoo.com.ph", "163.com", "yeah.net", "126.com", "21cn.com", "aliyun.com", "foxmail.com",
        "hotmail.fr", "live.fr", "laposte.net", "yahoo.fr", "wanadoo.fr", "orange.fr", "gmx.fr", "sfr.fr", "neuf.fr", "free.fr",
        "gmx.de", "hotmail.de", "live.de", "online.de", "t-online.de", "web.de", "yahoo.de",
        "libero.it", "virgilio.it", "hotmail.it", "aol.it", "tiscali.it", "alice.it", "live.it", "yahoo.it", "email.it", "tin.it", "poste.it", "teletu.it",
        "mail.ru", "rambler.ru", "yandex.ru", "ya.ru", "list.ru",
        "hotmail.be", "live.be", "skynet.be", "voo.be", "tvcablenet.be", "telenet.be",
        "hotmail.com.ar", "live.com.ar", "yahoo.com.ar", "fibertel.com.ar", "speedy.com.ar", "arnet.com.ar",
        "yahoo.com.mx", "live.com.mx", "hotmail.es", "hotmail.com.mx", "prodigy.net.mx",
        "yahoo.ca", "hotmail.ca", "bell.net", "shaw.ca", "sympatico.ca", "rogers.com",
        "yahoo.com.br", "hotmail.com.br", "outlook.com.br", "uol.com.br", "bol.com.br", "terra.com.br", "ig.com.br", "itelefonica.com.br", "r7.com", "zipmail.com.br", "globo.com", "globomail.com", "oi.com.br"
    ]

export const AnonStart = props =>  {
    const [page, setPage] = useState(0);
    const [submitError, setError] = useState(null);
    const [alreadySubmitted, setSubmitted] = useState(false);

    useEffect(() => {
        document.querySelector('body').scrollTo(0,0);
        window.scrollTo(0,0);

        const checkAlreadySubmitted = async () => {
            const time = await localStorage.getItem('anon-feedback');
            if(time) {
                if(new Date().getTime() < (Number(time) + 604800000)) setSubmitted(true);
            }
        }
        checkAlreadySubmitted();
    }, []);


    const nextPage = () => setPage(page + 1);

    const submit = async (comName, name, email, comments) => {
        try {
            const response = await BytehogsAPI('amsh-be', 'anonymousFeedback', {operation: 'submit', comName, name, email, comments});
            if(response.status === 200) {
                await localStorage.setItem('anon-feedback', new Date().getTime().toString());
                nextPage();
            } else {
                setError(response.data.message || "Something went wrong.");
            }
        } catch(e) {
            setError(e.message);
        }
    }

    const pages = [<Start nextPage={nextPage}/>, <Form submit={submit} submitError={submitError}/>, <Success/>];

    return <div className="view anon-feedback start">
        <SEO
            title="Give Anonymous Feedback To Your Manager | Are My Staff Happy"
            slug="anonymous-feedback"
        />
        <section className="hero">
            <div className="container">
                <h1>
                    Give Anonymous Feedback To Your Manager
                </h1>
                <p>Use our free form to give completely anonymous feedback to your manager.</p>
            </div>
        </section>
        <section className="get-started">
            <div className="container">
                {alreadySubmitted ? <AlreadySubmitted/> : pages[page]}
            </div>
        </section>
    </div>
}

const Start = ({nextPage}) => {
    const [authed, setAuth] = useState(false);

    const captchaChange = e => setAuth(e ? true : false);
    return <>
        <h4>Get Started</h4>
        <div className="d-flex">
            <ReCAPTCHA
                style={{ display: "block", marginLeft: "auto", marginRight: "auto", marginBottom: 20 }}
                sitekey="6LcCiygaAAAAANv_d5n3vtHjEMNo2bpS_bkWxH8y"
                onChange={captchaChange}
            />
        </div>
        <button disabled={!authed} className="btn btn-primary btn-rounded" onClick={nextPage}>Start Now</button>
    </>
}

const Form = ({submit, submitError}) => {
    const [errors, setErrors] = useState({});
    const [state, setState] = useState({comName: "", name:"", email:"", comments:"", loading: false});

    const setValue = (key, value) => {
        let newState = {...state}
        newState[key] = value;
        setState(newState);
    }

    const handleSubmit = () => {
        const {comName,name,email,comments} = state;
        let theErrors = {};
        setErrors(theErrors);
        if (!comName || comName.length < 2) theErrors.comName = 'Required';
        if (!name || name.length < 2) theErrors.name = 'Required';
        if (!email) theErrors.email = 'Required';
        else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i.test(email)) theErrors.email =  'Invalid Email';
        else if (personalEmailDomains.includes(email.split('@')[1].toLowerCase())) theErrors.email = 'Work Email Only';
        if (!comments || comments.length < 3) theErrors.submit = 'A comment is required';
        const isProfane = filter.isProfane(comments) || filter.isProfane(name) || filter.isProfane(comName);
        if(isProfane) theErrors.submit = 'Explicit comments aren\'t allowed';
        if(Object.keys(theErrors).length === 0) {
            setState({...state, loading: true});
            submit(comName,name,email,comments);
        }
    }

    useEffect(() => {
        if(submitError !== errors.submit) {
            setState({...state, loading: false});
            setErrors({...errors, submit: submitError});
        }
    }, [submitError])

    return <>
        <h4>Provide Feedback</h4>
        <div className="feedback-container">
            <BHGInputWrapper error={errors.comName}>
                <BHGInput type="text" error={errors.comName} value={state.comName} onChange={e => setValue('comName', e.target.value)} placeholder="Your Company's Name"/>
            </BHGInputWrapper>
            <BHGInputWrapper error={errors.name}>
                <BHGInput type="text" error={errors.name} value={state.name} onChange={e => setValue('name', e.target.value)} placeholder="Your Manager's Name"/>
            </BHGInputWrapper>
            <BHGInputWrapper error={errors.email}>
                <BHGInput type="text" error={errors.email} value={state.email} onChange={e => setValue('email', e.target.value)} placeholder="Your Manager's Email"/>
            </BHGInputWrapper>
            <textarea rows={4} value={state.comments} onChange={e => setValue('comments', e.target.value)} placeholder="Feedback comments" className="form-control"></textarea>
            {errors.submit && <div className="form-error">
                {errors.submit}
            </div>}
        </div>
        <button className="btn btn-primary btn-rounded" onClick={handleSubmit}>{state.loading ? "Submitting" : "Submit"}</button>
    </>
}

const Success = () => {
    return <>
        <h4>Success!</h4>
        <p>Thanks for submitting anonymous feedback to your manager.</p>
    </>
}

const AlreadySubmitted = () => {
    return <>
        <h4>Form Unavailable</h4>
        <div className="unavailable-container">
            <p>It looks like you've already submitted feedback to your manager. To prevent spam/abuse we don't let you submit multiple submissions in a short period of time.</p>
        </div>
    </>
}
