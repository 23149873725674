import React from "react";
import {SEO} from "../../components/global/SEO";
import {HomeCard} from "../../components/_marketing/HomeCard";
import slackImage from "../../assets/images/amsh-slack.svg";
import emailImage from "../../assets/images/integration-email.svg";
import teamsImage from "../../assets/images/microsoft-teams.svg";
import {Link} from "react-router-dom";

export const Integrations = props =>  {
    document.querySelector('body').scrollTo(0,0);
    window.scrollTo(0,0);
    return <div className="view integrations">
        <SEO
            title="Where we can integrate into | Are My Staff Happy"
            slug="integrations"
        />
        <section className="hero">
            <div className="container">
                <h1>
                    Our Integrations
                </h1>
                <p> We know you use several tools at work, so the last thing we want to do is add another one to manage - that's why we integrate with some of the most common tools.
                    <br/><br/>
                    We're new, so we haven't had a chance to build every integration we'd like to, if you want us to integrate with one of your tools, public or private, let us know at support@aremystaffhappy.com - if we integrate it we'll give you 3 months free access as a thank you!
                    <br/><br/>
                    Join us today and enjoy a free 14 day trial to get you started.
                </p>
            </div>
        </section>
        <section className="integration-list">
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <HomeCard
                            image={slackImage}
                            title="Slack"
                            content="Where work happens, we seamlessly integrate with your Slack Workspace.">
                            <div className="card-cta-container">
                                <Link className="btn btn-primary btn-rounded" to="/register">Join with Slack</Link>
                            </div>
                        </HomeCard>
                    </div>
                    <div className="col-md-4 email">
                        <HomeCard
                            image={emailImage}
                            title="Email"
                            content="Old fashioned, but never out of style - we can send surveys via Email.">
                            <div className="card-cta-container">
                                <Link className="btn btn-primary btn-rounded" to="/register">Join with Email</Link>
                            </div>
                        </HomeCard>
                    </div>
                    <div className="col-md-4">
                        <HomeCard
                            image={teamsImage}
                            title="Microsoft Teams"
                            content="We do corporate: Find our integration with Microsoft Teams.">
                            <div className="card-cta-container">
                                {/*<Link className="btn btn-primary btn-rounded" to="/register">Join with Teams</Link>*/}
                                <p className="coming-soon">Coming Soon 🏗</p>
                            </div>
                        </HomeCard>
                    </div>
                </div>
            </div>
        </section>
    </div>
}
