import React from "react";
import Slider from "rc-slider";
import 'rc-slider/assets/index.css';

export const Scale = props => {
    const {options, value, setAnswer} = props;
    let marks = {};

    if(JSON.stringify(marks) ===  '{}') {
        options.forEach((x, i) => {
            marks[i] = x.optionText
        })
    }

    return <div className="amsh-scale">
            <Slider marks={marks} included={false} min={0} max={options.length - 1} dots value={value} onChange={e => setAnswer(e + 1)}/>
        </div>
}
