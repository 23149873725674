import axios from "axios";
import {STAGE} from "../../getStage";

export const BytehogsAPI = (SERVICE, ENDPOINT, inputData, fullSource = null, noTokenRefresh = false) => {
    return new Promise((resolve,reject) => {
        let data = fullSource ? {...inputData} : JSON.stringify({...inputData})
        axios.post((fullSource ? fullSource : `https://${STAGE}.bytehogs.com/${SERVICE}/${ENDPOINT}`),data)
            .then(response => {
                resolve(response)
            })
            .catch(async error => {
                if(error.response && error.response.status === 401 && !noTokenRefresh) {
                    const sessionToken = await localStorage.getItem('sToken')
                    const refreshToken = await localStorage.getItem('rToken')
                    axios.post(`https://${STAGE}.bytehogs.com/amsh-be/token`, JSON.stringify({sessionToken, refreshToken, operation: "refresh"}))
                        .then(async response => {
                            data = fullSource ? {...inputData, sessionToken: response.data.sessionToken} : JSON.stringify({...inputData, sessionToken: response.data.sessionToken})
                            await localStorage.setItem("sToken", response.data.sessionToken)
                            await localStorage.setItem("rToken", response.data.refreshToken)
                            const resp = await axios.post((fullSource ? fullSource : `https://${STAGE}.bytehogs.com/${SERVICE}/${ENDPOINT}`), data)
                            resolve(resp)
                        })
                        .catch(err => {
                            //localStorage.removeItem("sToken")
                            //localStorage.removeItem("rToken")
                            console.log(err.response)
                            reject(err.response || err)
                        })
                } else {
                    reject(error.response || error)
                }
            })
    })
}
