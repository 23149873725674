import React, {Component} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import Cards from "react-credit-cards";
import {SEO} from "../../../components/global/SEO";
import {CompanyActionCreators} from "../../../redux/actions/company";
import {TitleWithHR} from "../../../components/_app/TitleWithHR";
import {Link} from "react-router-dom";

class Settings extends Component {
    state = {loading: false, name: "", website: ""};

    async componentWillMount() {
        const {actions} = this.props;
        await actions.getDetails();
        const {companyDetails} = this.props;
        this.setState({name: companyDetails.name, website: companyDetails.website});
    }

    setValue = (key, value) => {
        let newState = {...this.state};
        newState[key] = value;
        this.setState(newState);
    }

    handleSubmit = async e => {
        e.preventDefault();
        const {name,website, loading} = this.state;
        if(!loading) {
            let errors = {}
            this.setValue('errors', errors);
            this.setValue('loading', 'company');
            const validationErrors = this.handleValidation();
            if(validationErrors > 0) this.setValue('loading', false);
            else {
                const {actions} = this.props
                try {
                    await actions.updateDetails({name, website});
                    await actions.getDetails();
                    const {updateDetailsErr} = this.props;
                    if (updateDetailsErr) {
                        this.setValue('errors', {join: updateDetailsErr});
                        this.setValue('loading', false);
                    }
                } catch (e) {
                    this.setValue('errors', {join: e.message || "Sorry, something went wrong"});
                    this.setValue('loading', false);
                }
            }
        }
    }

    handleValidation = (onlyExistingErrors = false) => {
        const {name,website} = this.state;
        if(onlyExistingErrors && Object.keys(this.state.errors).length > 0 || !onlyExistingErrors) {
            let errors = {};
            this.setValue('errors', errors);
            if (!name || name.length < 6) errors.name = 'Required';
            if (!website || website.length < 6) errors.website = 'Required';
            else if (!/^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/i.test(website)) errors.website = 'Invalid Website'
            if (Object.keys(errors).length > 0) this.setValue('errors', errors);
            return Object.keys(errors).length;
        }
    }

    cancelSubscription = async () => {
        const {actions} = this.props;
        const {loading} = this.state;
        if(!loading) {
            this.setValue('loading', 'cancel');
            await actions.cancelSubscription();
            await actions.getDetails();
            this.setValue('loading', false);
        }
    }

    goToStripe = () => {
        const {actions} = this.props;
        actions.generateStripeSession();
    }

    renderSubscriptionStatus = () => {
        const {companyDetails: {billing: {subscription}}} = this.props;
        const {loading} = this.state;
        if(subscription && (subscription.status !== 'canceled' && subscription.status !== 'incomplete_expired') ) {
            return <div className="subscription-settings">
                <h5>Subscription Active</h5>
                <p>Your current plan is £12/month</p>
                {/*<div className="buttons left">
                    <button className="btn btn-primary btn-rounded" onClick={this.cancelSubscription}>{loading === 'cancel' ? "Cancelling" : "Cancel Subscription"}</button>
                </div>
                <p className="warning">Warning! Cancelling your subscription will stop all future reports from sending.</p>*/}
                <h6>Want to cancel your subscription?</h6>
                <p>No problem, just drop us an email to support@aremystaffhappy.com</p>
                <h5>Manage Billing Details</h5>
                <button className="btn btn-outline-primary btn-rounded" onClick={this.goToStripe}>Manage</button>
            </div>
        } else {
            return <div className="subscription-settings">
                <h5>No Subscription Present</h5>
                <div className="buttons left">
                    <Link className="btn btn-primary btn-rounded" to="/auth/billing">Subscribe Now</Link>
                </div>
            </div>
        }
    }

    renderCards = () => {
        const {companyDetails: {name, billing: {cards}}} = this.props;
        if(cards && cards.data && cards.data.length > 0) {
            return <div className="cards-settings">
                <h5>Your Cards</h5>
                <div className="row">
                    {cards.data.map(PM => {
                        let expiry;
                        if(PM.card.exp_month < 10) expiry = '0' + PM.card.exp_month;
                        else expiry = PM.card.exp_month;
                        expiry += '/' + PM.card.exp_year;
                        return <div key={PM.id} className="col-md-4">
                            <Cards
                                cvc={'000'}
                                issuer={PM.card.brand}
                                expiry={expiry}
                                preview={true}
                                name={name}
                                number={'**** **** **** ' + PM.card.last4}
                            />
                        </div>
                    })}
                </div>
            </div>
        }
    }

    render() {
        const {name, website, loading} = this.state;
        return <div className="view settings">
            <SEO title="Settings" slug="app/settings"/>
            <div className="container">
                <TitleWithHR title="Account Settings"/>
                <div className="amsh-card">
                    <label>Business Name</label>
                    <input value={name} className="form-control" placeholder="Business Name" onChange={e => this.setState({name: e.target.value})}/>
                    <label>Business Website</label>
                    <input value={website} className="form-control" placeholder="Business Website" onChange={e => this.setState({website: e.target.value})}/>
                    <div className="buttons">
                        <button className="btn btn-primary btn-rounded" onClick={this.handleSubmit}>{loading === 'company' ? "Updating" : "Update"}</button>
                    </div>

                </div>
                <TitleWithHR title="Manage Subscription"/>
                <div className="amsh-card mb-5">
                    {this.renderSubscriptionStatus()}
                    {/*{this.renderCards()}*/}
                </div>
            </div>
        </div>
    }
}

const mapStateToProps = (state) => ({
    companyDetails: state.CompanyReducer.companyDetails
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(CompanyActionCreators, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
