import React, {Component} from "react";
import {MetricCard} from "../../../components/_app/MetricCard";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {ReportsActionCreators} from "../../../redux/actions/reports";
import {ReportQuestionList} from "../../../components/_app/ReportQuestionList";
import {SEO} from "../../../components/global/SEO";

class ReportDetails extends Component {
    state = {loaded: false};

    async componentWillMount() {
        const {actions, match: {params: {id}}} = this.props;
        await actions.getById(id);
        this.setState({loaded: true})
    }

    render() {
        const {reportDetails, match: {params: {id}}} = this.props;
        const report = reportDetails[id] || {};
        const {keyInsights} = report;
        const sentDate = new Date(report && report.createdAt).toDateString();
        document.querySelector('body').scrollTo(0,0);
        window.scrollTo(0,0);
        return <div className="view app report-details">
            <SEO title={"Report " + sentDate + " | Are My Staff Happy"}/>
            <div className="container">
                <div className="app-intro">
                    <h1>Report Summary</h1>
                    <span className="sent-date">{sentDate}</span>
                </div>

                <div>
                    <span>Key Insights</span>
                    <hr/>
                    <div className="row mb-5">
                        <div className="col-md-4">
                            <MetricCard metric="Response Rate" value={(keyInsights && keyInsights.responseRate) || 0} valueExtra="%"/>
                        </div>
                        <div className="col-md-4">
                            <MetricCard metric="Happiness" value={(keyInsights && keyInsights.happiness) || 0}/>
                        </div>
                        <div className="col-md-4">
                            <MetricCard metric="Social" value={(keyInsights && keyInsights[1]) || 0}/>
                        </div>
                        <div className="col-md-4">
                            <MetricCard metric="Reward" value={(keyInsights && keyInsights[2]) || 0}/>
                        </div>
                        <div className="col-md-4">
                            <MetricCard metric="Environment" value={(keyInsights && keyInsights[3]) || 0}/>
                        </div>
                        <div className="col-md-4">
                            <MetricCard metric="Balance" value={(keyInsights && keyInsights[4]) || 0}/>
                        </div>
                    </div>
                </div>
                <div>
                    <span>Questions</span>
                    <hr/>
                    <ReportQuestionList questions={(report && report.questions) || []}/>
                </div>
            </div>
        </div>
    }
}

const mapStateToProps = (state) => ({
    reportDetails: state.ReportsReducer.reportDetails
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(ReportsActionCreators, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ReportDetails);
