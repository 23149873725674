import React, {useState} from "react";
import {bindActionCreators} from "redux";
import {CompanyActionCreators} from "../../../../redux/actions/company";
import {connect} from "react-redux";
import {AuthCard} from "../../../../components/_app/AuthCard";
import {SEO} from "../../../../components/global/SEO";
import {Link, Redirect} from "react-router-dom";
import {SLACK_REDIRECT_URI} from "../../../../getStage";
import slackImage from "../../../../assets/images/amsh-slack.svg";
import {HomeCard} from "../../../../components/_marketing/HomeCard";
import emailImage from "../../../../assets/images/integration-email.svg";

const WizIntegrations = props => {
    return <div className="view auth integrations">
        <SEO title="Choose your integration" slug="auth/integrations"/>
        <AuthCard>
            <h3>Integrations</h3>
            <p>Choose where you want your staff members to receive their anonymous survey link.</p>
            <div className="row integrations-list">
                <div className="col-md-6">
                    <HomeCard
                        image={slackImage}
                        title="Slack">
                        <div className="cta-container">
                            <Link className="btn btn-primary btn-rounded" to="/auth/slack">Continue</Link>
                        </div>
                    </HomeCard>
                </div>
                <div className="col-md-6 email">
                    <HomeCard
                        image={emailImage}
                        title="Email">
                        <div className="cta-container">
                            <Link className="btn btn-primary btn-rounded" to="/auth/email">Continue</Link>
                        </div>
                    </HomeCard>
                </div>
            </div>
            </AuthCard>
    </div>
}

const mapStateToProps = (state) => ({
    updateDetailsErr: state.CompanyReducer.updateDetailsErr
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(CompanyActionCreators, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(WizIntegrations);
