import React from "react";
import {ReportChartRenderer} from "./ReportChartRenderer";

export const ReportQuestion = props => {
    const {questionText, questionType, responses, options, index} = props;
    return <div className="report-question">
        <span className="question-number">Question {index + 1}</span>
        <p className="question-text">{questionText}</p>
        <div className="report-chart">
            <ReportChartRenderer questionType={questionType} responses={responses} options={options}/>
        </div>
    </div>
}
