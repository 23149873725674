import React from "react";
import {ResponsivePie} from "@nivo/pie";
import {ResponsiveBar} from "@nivo/bar";

const mapData = (responses, options) => {
    let data = [];
    let counts = {};
    options.forEach(o => {
        counts[o.id] = 0;
    });
    responses.forEach(r => {
        counts[r.optionId]++;
    });
    Object.keys(counts).forEach(c => {
        let label = options.find(x => x.id == c);
        label = label.optionText;
        data.push({
            id: label,
            label,
            value: counts[c]
        })
    });
    return data;
}

export const ReportChartRenderer = props => {
    const {questionType, responses, options} = props;
    if(questionType === 3 || questionType === 4 || questionType === 2) {
        const data = mapData(responses, options)
        return <ResponsivePie
            data={data}
            margin={{ top: 20, right: 40, bottom: 120, left: 40 }}
            colors={['#E91E63', '#28895E', '#FF9800']}
            borderWidth={1}
            borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
            enableRadialLabels={false}
            animate={true}
            motionStiffness={90}
            motionDamping={15}
            enableSliceLabels={false}
            sliceLabel={'id0'}
            slicesLabelsSkipAngle={10}
            axisBottom={{
                tickSize: 5,
                tickRotation: -20
            }}
            legends={[
                {
                    anchor: 'bottom',
                    direction: 'column',
                    translateY:50,
                    translateX: 150,
                    itemWidth: 100,
                    itemHeight: 18,
                    itemTextColor: '#999',
                    symbolSize: 14,
                    symbolShape: 'circle',
                    marginBottom: 0
                }
            ]}
        />
    } else if(questionType === 2) {
        const data =  mapData(responses, options);
        return <ResponsiveBar
            data={data}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
            layout="horizontal"
            axisLeft={{
                tickSize: 5,
                tickRotation: -20
            }}
            margin={{ top: 20, right: 20, bottom: 120, left: 250 }}
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            colors={['#1E40C8']}
            pixelRatio={3}
            borderWidth={1}
            borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
            enableLabel={false}
            enableGridY={false}
            enableRadialLabels={false}
            animate={true}
            motionStiffness={90}
            motionDamping={15}

        />
    } else {
        return <div>
            {responses.map(x => {
                return <div key={x.id}>{x.plainTextAnswer}</div>
            })}
        </div>
    }
}
