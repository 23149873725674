import React, {useState} from "react";
import {GoogleButton} from "../buttons/GoogleButton";
import {AuthCard} from "./AuthCard";
import {BHGInputWrapper, BHGInput} from "@bytehogs/design-system";
import EmailIcon from "../../assets/images/amsh-email.svg";
import PwIcon from "../../assets/images/amsh-password.svg";

export const SigninCard = (props) => {
    const {title, content, handleGoogleClick, handleSubmit, register = false, email, password, errors, loading, setValue} = props;

    return (
        <AuthCard>
            <span className="title">{title}</span>
            <p className="content"> {content} </p>
            <GoogleButton onClick={handleGoogleClick}/>
            <div className="or">
                <hr/>
                <span>or</span>
            </div>
            <form onSubmit={handleSubmit}>
                <BHGInputWrapper error={errors.email}>
                    <img src={EmailIcon} alt="Email" className="form-icon email"/>
                    <BHGInput type="text" error={errors.email} value={email} onChange={e => setValue('email', e.target.value)} placeholder="Email"/>
                </BHGInputWrapper>
                <BHGInputWrapper error={errors.password}>
                    <img src={PwIcon} alt="Pw" className="form-icon pw"/>
                    <BHGInput type="password" error={errors.email} value={password} onChange={e => setValue('password', e.target.value)} placeholder="Password"/>
                </BHGInputWrapper>
                {errors.join && <div className="form-error">{errors.join}</div>}
                <div className="actions">
                    <button type="submit" className="btn btn-primary btn-rounded">{loading ? "Loading..." : register ? "Start Trial" : "Sign In"}</button>
                </div>
            </form>
        </AuthCard>
    );
};
