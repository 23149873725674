import React from "react";
import {Scale} from "./Scale";
import {Checkboxes} from "./Checkboxes";
import {Radios} from "./Radios";
import {TextInputs} from "./TextInputs";

export const OptionsList = props => {
    const {options, questionType, answers, setAnswer, template = false} = props;

    const MultiSelect = e => {
        let theAnswers = [...answers];
        let thisSelect = Number(e)
        if(theAnswers.includes(thisSelect)) theAnswers.splice(theAnswers.indexOf(thisSelect), 1);
        else theAnswers.push(thisSelect);
        setAnswer(theAnswers);
    }

    const RadioSelect = e => {
        let theAnswers = [...answers];
        let thisSelect = Number(e)
        if(theAnswers.includes(thisSelect)) theAnswers = [];
        else theAnswers = [thisSelect];
        setAnswer(theAnswers);
    }

    const renderer = () => {
       if(template) {
           return <div>
               {options.map(x => <div key={x.id}  className="display-option">{x.optionText}</div>)}
           </div>
       } else {
           switch (questionType) {
               case 1:
                   return <TextInputs value={answers} setAnswer={setAnswer}/>
               case 2:
                   return <Radios options={options} value={answers} setAnswer={RadioSelect}/>
               case 3:
                   return <Checkboxes options={options} value={answers} setAnswer={MultiSelect}/>
               case 4:
                   return <Scale options={options} value={answers[0]} setAnswer={e => setAnswer([e])}/>
               default:
                   return options.map(x => <div>{x.optionText}</div>)
           }
       }
    }

    if(!options || options.length === 0 && questionType !== 1) return <div className="text-center">
        <h2>No options</h2>
    </div>
    return <div className="options-list">
        {renderer()}
    </div>
};
