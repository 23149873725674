import React from "react";
import {ReportItem} from "./ReportItem";
import {ReportQuestion} from "./ReportQuestion";

export const ReportQuestionList = props => {
    const {questions, admin = false} = props;
    if(questions.length === 0) return <div>
        <h4>No Questions</h4>
    </div>
    return <div className="report-question-list">
        <div className="row">
            {questions.map((q, i) => <div className="col-12" key={q.id} ><ReportQuestion index={i} {...q}/></div>)}
        </div>
    </div>
}
