import {AuthActionTypes} from "../../types";
import {BytehogsAPI} from "../BytehogsAPI";
import {reset} from "redux-form";
import {Auth} from "aws-amplify";

const SERVICE = 'amsh-be';

export const AuthActionCreators = {
    checkToken: () => async (dispatch) => {
        const sessionToken = await localStorage.getItem('sToken');
        const refreshToken = await localStorage.getItem('rToken');
        try {
            let user;
            try {
                user = await Auth.currentAuthenticatedUser();
            } catch (e) {
                console.log("e", e);
            }
//|| !user || !user.signInUserSession.getRefreshToken()
            if (!sessionToken || !refreshToken || (user && !user.signInUserSession.getRefreshToken())) {
                dispatch({
                    type: AuthActionTypes.AUTH_STATUS,
                    error: "No Auth"
                });
            } else {
                try {
                    await BytehogsAPI(SERVICE, 'token', {
                        operation: 'check',
                        sessionToken,
                        refreshToken
                    })
                    dispatch({
                        type: AuthActionTypes.AUTH_STATUS,
                        error: null
                    });
                } catch (e) {
                    dispatch({
                        type: AuthActionTypes.AUTH_STATUS,
                        error: "No Auth"
                    });
                }
            }
        } catch (e) {
            dispatch({
                type: AuthActionTypes.AUTH_STATUS,
                error: "No Auth"
            });
        }
    },
    login: data => async (dispatch) => {
        dispatch({
            type: AuthActionTypes.LOGIN_REQ
        });
        try {
            let payload;
            if(data) {
                payload = {...data}
            } else {
                const user = await Auth.currentAuthenticatedUser();
                const {attributes: {email, sub}} = user;
                payload = {email, cognitoSub: sub};
            }
            const response = await BytehogsAPI(SERVICE, 'login', payload, null, true);
            localStorage.setItem("sToken", response.data.sessionToken);
            localStorage.setItem("rToken", response.data.refreshToken);
            dispatch({
                type: AuthActionTypes.LOGIN_RES,
                error: null
            })
        } catch (e) {
            dispatch({
                type: AuthActionTypes.LOGIN_RES,
                error: e.data.message || e.message || "Something went wrong"
            })
        }
    },
    register: data => async (dispatch) => {
        dispatch({
            type: AuthActionTypes.REGISTER_REQ
        });
        try {
            let payload = {...data};
            await BytehogsAPI(SERVICE, 'emailRegister', payload, null, true);
            dispatch({
                type: AuthActionTypes.REGISTER_RES,
                error: null
            })
        } catch (e) {
            dispatch({
                type: AuthActionTypes.REGISTER_RES,
                error: e.data.message || e.message || "Something went wrong"
            })
        }
    },
    resetForm: formName => async (dispatch) => {
        dispatch(reset(formName))
    },
    logout: () => async (dispatch) => {
        await localStorage.removeItem("sToken");
        await localStorage.removeItem("rToken");
        await Auth.signOut();
        dispatch({
            type: "LOGOUT"
        });
    }
}
