import React from "react";
import {Link} from "react-router-dom";
import Arrow from "../../assets/images/amsh-arrow.svg";

export const ReportItem = props => {
    const {id, createdAt, sentTo, responseRate} = props;
    const sentDate = new Date(createdAt).toDateString();

    return <Link to={"/app/reports/"+id}>
        <div className="report-item">
            <div className="row">
                <div className="col-7">
                    <span className="report-date">{sentDate}</span>
                    <span className="sent-to">Sent to {sentTo} staff members</span>
                    <span className="response-rate">{Math.round(responseRate * 100).toFixed(0)}% response rate</span>
                </div>
                <div className="col-2 col-md-3">

                </div>
                <div className="col-2">
                    <img src={Arrow} alt="Open" className="arrow"/>
                </div>
            </div>
        </div>
    </Link>
}
