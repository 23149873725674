import {ReportsActionTypes} from "../../types";

export const initialState = {
  loading: false,
  allReports: [],
  keyInsights: {1:0, 2:0, 3:0, 4:0},
  reportsErr: null,
  reportDetails: {}
};

export const ReportsReducer = (state = initialState, action) => {
  const {type, payload, error} = action;
  switch (type) {
    case ReportsActionTypes.GET_REPORTS_REQ: {
      return {
        ...state,
        loading: true
      }
    }
    case ReportsActionTypes.GET_REPORTS_RES: {
      if (error) {
        return {
          ...state,
          loading: false,
          reportsErr: error
        }
      }
      return {
        ...state,
        loading: false,
        reportsErr: null,
        allReports: payload.reports,
        keyInsights: payload.keyInsights,
      }
    }
    case ReportsActionTypes.GET_BYID_REQ: {
      return {
        ...state,
        loading: true
      }
    }
    case ReportsActionTypes.GET_BYID_RES: {
      if (error) {
        return {
          ...state,
          loading: false,
          reportsErr: error
        }
      }
      return {
        ...state,
        loading: false,
        reportsErr: null,
        reportDetails: {...state.reportDetails, [payload.formId]: payload.data.report}
      }
    }
    case "LOGOUT": {
      return initialState
    }
    default:
      return state
  }
}
