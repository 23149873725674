import React, {Component} from "react";
import {bindActionCreators} from "redux";
import {AuthActionCreators} from "../../../redux/actions/auth";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import {SEO} from "../../../components/global/SEO";
import {AuthCard} from "../../../components/_app/AuthCard";
import {CompanyActionCreators} from "../../../redux/actions/company";

class AuthRedirect extends Component {
    state = {loading: true, redirectLocation: "/app"};

    async componentWillMount() {
        const {actions, comActions} = this.props;
        if(!this.props.isAuthed) await actions.login();
        const {loginErr, isAuthed} = this.props;
        await comActions.getDetails();
        const {companyDetails} = this.props;
        const {billing} = companyDetails;
        if(isAuthed) {
            let redirectLocation;
            if(!companyDetails.name || !companyDetails.website) redirectLocation = '/auth/details';
            else if(!companyDetails.integrations || companyDetails.integrations.length === 0 ) redirectLocation = '/auth/integrations';
            //else if((!billing || !billing.subscription) || (billing && billing.subscription && billing.subscription.status !== 'active')) redirectLocation = '/auth/billing';
            else redirectLocation = '/app'
            this.setState({loading: false, redirectLocation})
        }
    }

    render() {
        const {loading,redirectLocation} = this.state;
        return <div className="view auth login">
            <SEO title="Authenticating..." slug="login"/>
            <AuthCard>
                {loading ? <div>Loading...</div> : <Redirect to={redirectLocation}/>}
            </AuthCard>
        </div>
    }
}

const mapStateToProps = (state) => ({
    loginErr: state.AuthReducer.loginErr,
    isAuthed: state.AuthReducer.isAuthed,
    companyDetails: state.CompanyReducer.companyDetails,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(AuthActionCreators, dispatch),
    comActions: bindActionCreators(CompanyActionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(AuthRedirect);
