import {ReportsActionTypes} from "../../types";
import {BytehogsAPI} from "../BytehogsAPI";

const SERVICE = 'amsh-be';

export const ReportsActionCreators = {
    getAll: () => async (dispatch) => {
        dispatch({
            type: ReportsActionTypes.GET_REPORTS_REQ
        });
        try {
            const sessionToken = await localStorage.getItem('sToken');
            let payload = {sessionToken, operation: "getAll"};
            const response = await BytehogsAPI(SERVICE, 'reports', payload);
            dispatch({
                type: ReportsActionTypes.GET_REPORTS_RES,
                error: null,
                payload: response.data
            })
        } catch (e) {
            dispatch({
                type: ReportsActionTypes.GET_REPORTS_RES,
                error: e.data.message || e.message || "Something went wrong"
            })
        }
    },
    getById: formId => async (dispatch) => {
        dispatch({
            type: ReportsActionTypes.GET_BYID_REQ
        });
        try {
            const sessionToken = await localStorage.getItem('sToken');
            let payload = {sessionToken, formId, operation: "getById"};
            const response = await BytehogsAPI(SERVICE, 'reports', payload);
            dispatch({
                type: ReportsActionTypes.GET_BYID_RES,
                error: null,
                payload: {formId, data: response.data}
            })
        } catch (e) {
            dispatch({
                type: ReportsActionTypes.GET_BYID_RES,
                error: e.data.message || e.message || "Something went wrong"
            })
        }
    }
}
