import React from "react";
import {SEO} from "../../components/global/SEO";
import {HomeCard} from "../../components/_marketing/HomeCard";
import slackImage from "../../assets/images/amsh-slack.svg";
import emailImage from "../../assets/images/integration-email.svg";
import teamsImage from "../../assets/images/microsoft-teams.svg";
import {Link} from "react-router-dom";

export const Pricing = props =>  {
    document.querySelector('body').scrollTo(0,0);
    window.scrollTo(0,0);
    return <div className="view integrations pricing">
        <SEO
            title="Simple £12 a month pricing | Are My Staff Happy"
            slug="pricing"
        />
        <section className="hero">
            <div className="container">
                <h1>
                    We do pricing differently
                </h1>
                <p>One flat monthly fee no matter how big your team is. We believe employee satisfaction should be affordable whether your team is big or small.</p>
            </div>
        </section>
        <section className="details">
            <div className="container">
                <h4>Why £12 a month?</h4>
                <p>When we built Are My Staff Happy, our primary aim was to make work a better place to be. We couldn't morally charge a large fee for our service, however with that said, we do have some (very small) operating costs and the £12 a month fee helps us to cover those.</p>
                <p>We're still super small so we're not VAT registered yet, but when we are we'll drop our fee to £10 a month plus VAT, keeping the total cost at £12.</p>
                <h4>Got a free trial?</h4>
                <p>Yep! We've got a 14 day free trial for you to test the waters with our completely anonymous and automated surveys - all without needing to enter a credit card.</p>
            </div>
        </section>
        <section className="cta-container">
            <div className="container">
                <h3>Can't wait to try us out?</h3>
                <p>We can't wait either - let's get started!</p>
                <Link to="register" className="btn btn-primary btn-rounded">Start 14 Day Free Trial</Link>
                <span className="no-cc-req">No credit card required</span>
            </div>
        </section>
    </div>
}
