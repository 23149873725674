import {FormsActionTypes} from "../../types";
import {BytehogsAPI} from "../BytehogsAPI";

const SERVICE = 'amsh-be';

export const FormsActionCreators = {
    getTemplateQuestions: () => async (dispatch) => {
        dispatch({
            type: FormsActionTypes.GET_TQUES_REQ
        });
        try {
            const sessionToken = await localStorage.getItem('sToken');
            let payload = {sessionToken, operation: "getTemplateQuestions"};
            const response = await BytehogsAPI(SERVICE, 'forms', payload);
            dispatch({
                type: FormsActionTypes.GET_TQUES_RES,
                error: null,
                payload: response.data
            })
        } catch (e) {
            dispatch({
                type: FormsActionTypes.GET_TQUES_RES,
                error: e.data ? e.data.message || e.message :  "Something went wrong"
            })
        }
    },
    updateTemplate: data => async (dispatch) => {
        dispatch({
            type: FormsActionTypes.GET_TQUES_REQ
        });
        try {
            const sessionToken = await localStorage.getItem('sToken');
            let payload = {...data, sessionToken, operation: "updateTemplate"};
            await BytehogsAPI(SERVICE, 'forms', payload);
            dispatch({
                type: FormsActionTypes.GET_TQUES_RES,
                error: null
            })
        } catch (e) {
            dispatch({
                type: FormsActionTypes.GET_TQUES_RES,
                error: e.data ? e.data.message || e.message :  "Something went wrong"
            })
        }
    },
    checkFormToken: (token) => async (dispatch) => {
        dispatch({
            type: FormsActionTypes.CHECK_FTOKEN_REQ
        });
        try {
            let payload = {token, operation: "checkFormToken"};
            const response = await BytehogsAPI(SERVICE, 'forms', payload);
            dispatch({
                type: FormsActionTypes.CHECK_FTOKEN_RES,
                error: null,
                payload: response.data
            })
        } catch (e) {
            dispatch({
                type: FormsActionTypes.CHECK_FTOKEN_RES,
                error: e.data ? e.data.message || e.message :  "Something went wrong"
            })
        }
    },
    respond: data => async (dispatch) => {
        dispatch({
            type: FormsActionTypes.RESPOND_REQ
        });
        try {
            let payload = {...data, operation: "respond"};
            const response = await BytehogsAPI(SERVICE, 'forms', payload);
            dispatch({
                type: FormsActionTypes.RESPOND_RES,
                error: null,
                payload: response.data
            })
        } catch (e) {
            dispatch({
                type: FormsActionTypes.RESPOND_RES,
                error: e.data ? e.data.message || e.message :  "Something went wrong"
            })
        }
    },
}
