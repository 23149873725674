import React, {useState} from "react";
import {ParameterModal} from "./ParameterModal";

const valueColors = {
  70: "#28895E",
  50: "#4CAF50",
  40: "#FF9800",
  0: "#E91E63",
}

const valueMsgs = {
  70: "Very Good",
  50: "Good",
  40: "Could be better",
  0: "Bad",
}

const getData = value => {
  let data = {color: valueColors[0], msg: valueMsgs[0]};
  if(value >= 70) data = {color: valueColors[70], msg: valueMsgs[70]};
  else if(value >= 50) data = {color: valueColors[50], msg: valueMsgs[50]};
  else if(value >= 40) data = {color: valueColors[40], msg: valueMsgs[40]};
  return data;
}

export const MetricCard = (props) => {
  const { metric, value, valueExtra = '' } = props;
  const [modalOpen, setModal] = useState(false);
  const data = getData(Number(value));
  return (
    <div className="metric-card" style={{backgroundColor: data.color}}>
        <span className="metric-title">{metric}</span>
      {(!['Response Rate','Happiness'].includes(metric)) && <i className="fa fa-question-circle fa-2x" onClick={() => setModal(true)}/> }
        <span className="metric-value">{(isNaN(value) ? 0 : value) + valueExtra}</span>
        <span className="metric-msg">{data.msg}</span>
      <ParameterModal isOpen={modalOpen} close={() => setModal(false)} parameter={metric}/>
    </div>
  );
};
