import React, {Component} from "react";
import {bindActionCreators} from "redux"
import {connect} from "react-redux";
import {SEO} from "../../../components/global/SEO";
import {FormsActionCreators} from "../../../redux/actions/forms";
import {QuestionsList} from "../../../components/_app/QuestionsList";

class SurveyResponse extends Component {
    state = {loading: true, ready: false, answers: {}, errors: {}, success: false};

    async componentDidMount() {
        const {actions, match: {params: {token}}} = this.props;
        await actions.checkFormToken(token);
        const {checkFTokenErr, questions} = this.props;
        let ready = false;
        let answers = {};
        if(!checkFTokenErr) {
            ready = true;
            questions.forEach(x => {
                answers[x.id] = []
            });
        }
        this.setState({loading: false, ready, answers});
    }

    setAnswer = (questionId, value) => {
        let {answers} = this.state;
        answers[questionId] = value;
        this.setState({answers})
    }

    submitAnswers = async () => {
        const {answers} = this.state;
        const {actions, match: {params: {token}}} = this.props;
        this.setState({loading: true});
        let theAnswers = [];
        Object.keys(answers).forEach(x => {
            let values = answers[x];
            if(Array.isArray(values)) {
                values.forEach(y => theAnswers.push({questionId: x, optionId: y}));
            } else theAnswers.push({questionId: x, optionId: values});
        })
        await actions.respond({token, answers: theAnswers});
        const {respondErr} = this.props;
        let errors = {};
        let success = true;
        if(respondErr) {
            errors.join = respondErr;
            success = false;
        }
        this.setState({loading: false, success, errors});
    }

    render() {
        const {ready, answers, loading, success} = this.state;
        const {questions, heading, checkFTokenErr} = this.props;

        return <div className="view survey">
            <SEO title="Rate your happiness" slug="survey"/>
            <div className="container mb-5">
                {checkFTokenErr &&  <div className="amsh-card">
                    <div className="text-center">
                        {checkFTokenErr}
                    </div>
                </div>}
                {!ready && !checkFTokenErr ? <div className="amsh-card">
                    <div className="text-center">
                        Loading...
                    </div>
                </div> : !checkFTokenErr ? <div className={"form-container " + (success ? "success" : "")}>
                    <h3>{heading}</h3>
                    <div className="main-body">
                        <QuestionsList questions={questions} answers={answers} setAnswer={this.setAnswer}/>
                        <div className="buttons">
                            <button className="btn btn-primary" onClick={this.submitAnswers}>{loading ? "Submitting" : "Submit"}</button>
                        </div>
                    </div>
                    <div className="success-content">
                        <div className="amsh-card">
                            <h3>Thank you for completing!</h3>
                        </div>
                    </div>
                </div> : null}
            </div>
        </div>
    }
}

const mapStateToProps = (state) => ({
    checkFTokenErr: state.FormsReducer.checkFTokenErr,
    questions: state.FormsReducer.questions,
    heading: state.FormsReducer.heading,
    respondErr: state.FormsReducer.respondErr,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(FormsActionCreators, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(SurveyResponse);
