import React from "react";
import { Link, withRouter } from "react-router-dom";
import { Logo } from "../global/Logo";

const MHeader = (props) => {
  return (
    <header className={"main-header marketing-header"}>
      <div className="container">
        <div className="left">
          <div className="brand">
            <Link to="/" className="desktop">
              <Logo />
            </Link>
            <Link to="/" className="mobile">
              <Logo mobile />
            </Link>
          </div>
        </div>

        <nav>
         {/* <a href="/#features">Features</a>*/}
          <Link to="/pricing">Pricing</Link>
          {/*<a href="/#hiw">How it works</a>*/}
          <Link to="/integrations">Integrations</Link>
          <Link to="/blog">Blog</Link>
        </nav>

        <div className="header-actions">
          <Link to="/login" className="btn btn-primary">
            Login
          </Link>
        </div>
      </div>
    </header>
  );
};

export default withRouter(MHeader);
