import React from "react";
import { Link, withRouter } from "react-router-dom";
import { Logo } from "../global/Logo";

const AuthHeader = (props) => {
  const {location: {pathname}} = props;
  const isLoginRegister = ['/auth/login', '/auth/register'].includes(pathname);
  return (
    <header className={"main-header marketing-header"}>
      <div className="container">
        <div className="left">
          <div className="brand">
            <Link to={isLoginRegister ? '/' : pathname} className="desktop">
              <Logo />
            </Link>
            <Link to={isLoginRegister ? '/' : pathname} className="mobile">
              <Logo mobile />
            </Link>
          </div>
        </div>
      </div>
    </header>
  );
};

export default withRouter(AuthHeader);
