import React from "react";
import {OptionsList} from "./OptionsList";

export const Question = props => {
    const {questionText, questionType, options, answers, setAnswer, index, template = false, parameterId, parameterName} = props;
    return <div className="question">
        <span className="question-number">Question {index + 1}</span>
        <p className="question-text">{questionText}</p>
        <OptionsList questionType={questionType} options={options} answers={answers} setAnswer={setAnswer} template={template}/>
        {template && <div className="parameters">
            <span>This question measures </span>
            <div className={"parameter pm-"+parameterId}>{parameterName}</div>
        </div>}
    </div>
}
