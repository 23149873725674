import React from "react";

export const Checkboxes = props => {
    const {options, value, setAnswer} = props;

    return <div className="amsh-checkboxes">
        {options.map(x => {
            return <label className="checkbox">
                <input type="checkbox" value={x.id} checked={value.includes(x.id)} onChange={e => setAnswer(e.target.value)}/>
                <span>{x.optionText}</span>
            </label>
        })}
    </div>
}
