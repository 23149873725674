import React, {useEffect, useState} from "react";
import {bindActionCreators} from "redux";
import {CompanyActionCreators} from "../../../../redux/actions/company";
import {connect} from "react-redux";
import {AuthCard} from "../../../../components/_app/AuthCard";
import {SEO} from "../../../../components/global/SEO";
import {Redirect} from "react-router-dom";
import NewEmployeeForm from "../../../../components/_app/NewEmployeeForm";

const AddEmail = props => {
    const [redirect, setRedirect] = useState(false);

    useEffect(() => {
        if(props.addEmpSuccess && !redirect) {
            setRedirect(true);
        }
    }, [props.addEmpSuccess])

    return <div className="view auth add-slack">
        <SEO title="Connect with Email" slug="auth/email"/>
        {redirect && <Redirect to="/app/forms"/>}
        <AuthCard>
            <h3>Add Staff Members</h3>
            <div>
                <p className="content">The final stage is to add your staff members. This will allow us to automatically send an employee satisfaction survey to your team. </p>
            </div>
            <NewEmployeeForm/>
            </AuthCard>
    </div>
}

const mapStateToProps = (state) => ({
    addEmpSuccess: state.CompanyReducer.addEmpSuccess
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(CompanyActionCreators, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(AddEmail);
