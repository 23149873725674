import React from "react";

export const AuthCard = (props) => {
  const { children } = props;
  return (
    <div className="signin-card auth-card">
        {children}
    </div>
  );
};
