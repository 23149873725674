import {FormsActionTypes} from "../../types";

export const initialState = {
  loading: false,
  getQuestionsErr: null,
  formTemplate: {},
  checkFTokenErr: null,
  questions: [],
  heading: null,
  respondErr: null
};

export const FormsReducer = (state = initialState, action) => {
  const {type, payload, error} = action;
  switch (type) {
    case FormsActionTypes.GET_TQUES_REQ: {
      return {
        ...state,
        loading: true
      }
    }
    case FormsActionTypes.GET_TQUES_RES: {
      if (error) {
        return {
          ...state,
          loading: false,
          getQuestionsErr: error
        }
      }
      return {
        ...state,
        loading: false,
        getQuestionsErr: null,
        formTemplate:  payload.formTemplate
      }
    }
    case FormsActionTypes.CHECK_FTOKEN_REQ: {
      return {
        ...state,
        loading: true
      }
    }
    case FormsActionTypes.CHECK_FTOKEN_RES: {
      if (error) {
        return {
          ...state,
          loading: false,
          checkFTokenErr: error
        }
      }
      return {
        ...state,
        loading: false,
        checkFTokenErr: null,
        questions:  payload.questions,
        heading:  payload.heading,
      }
    }
    case FormsActionTypes.RESPOND_REQ: {
      return {
        ...state,
        loading: true
      }
    }
    case FormsActionTypes.RESPOND_RES: {
      if (error) {
        return {
          ...state,
          loading: false,
          respondErr: error
        }
      }
      return {
        ...state,
        loading: false,
        respondErr: null
      }
    }
    case "LOGOUT": {
      return initialState
    }
    default:
      return state
  }
}
