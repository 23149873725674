import React from "react";

export const EmployeeListItem = props => {
    const {name, email, integration} = props;
    return <div className="employee-list-item">
        <div className="row">
            <div className="col-md-2">
                {name}
            </div>
            <div className="col-md-3">
                {email}
            </div>
            <div className="col-md-3">
                {integration}
            </div>
        </div>
    </div>
}
