export const AuthActionTypes = {
  AUTH_STATUS: "AUTH_STATUS",
  REGISTER_REQ: "REGISTER_REQ",
  REGISTER_RES: "REGISTER_RES",
  LOGIN_REQ: "LOGIN_REQ",
  LOGIN_RES: "LOGIN_RES",
  FORGOT_PW_REQ: "FORGOT_PW_REQ",
  FORGOT_PW_RES: "FORGOT_PW_RES",
  SET_PW_REQ: "SET_PW_REQ",
  SET_PW_RES: "SET_PW_RES",
  JOIN_TEAM_REQ: "JOIN_TEAM_REQ",
  JOIN_TEAM_RES: "JOIN_TEAM_RES",
}

export const CompanyActionTypes = {
  ADD_INTEGRATION_REQ: "ADD_INTEGRATION_REQ",
  ADD_INTEGRATION_RES: "ADD_INTEGRATION_RES",
  UPDATE_DETAILS_REQ: "UPDATE_DETAILS_REQ",
  UPDATE_DETAILS_RES: "UPDATE_DETAILS_RES",
  GET_DETAILS_REQ: "GET_DETAILS_REQ",
  GET_DETAILS_RES: "GET_DETAILS_RES",
  ADD_PAYM_REQ: "ADD_PAYM_REQ",
  ADD_PAYM_RES: "ADD_PAYM_RES",
  CREATE_SUB_REQ: "CREATE_SUB_REQ",
  CREATE_SUB_RES: "CREATE_SUB_RES",
  CANCEL_SUB_REQ: "CANCEL_SUB_REQ",
  CANCEL_SUB_RES: "CANCEL_SUB_RES",
  CHECK_PAY_REQ: "CHECK_PAY_REQ",
  CHECK_PAY_RES: "CHECK_PAY_RES",
  ADD_EMP_REQ: "ADD_EMP_REQ",
  ADD_EMP_RES: "ADD_EMP_RES",
}

export const FormsActionTypes = {
  CHECK_FTOKEN_REQ: "CHECK_FTOKEN_REQ",
  CHECK_FTOKEN_RES: "CHECK_FTOKEN_RES",
  RESPOND_REQ: "RESPOND_REQ",
  RESPOND_RES: "RESPOND_RES",
  GET_TQUES_REQ: "GET_TQUES_REQ",
  GET_TQUES_RES: "GET_TQUES_RES",
}

export const ReportsActionTypes = {
  GET_REPORTS_REQ: "GET_REPORTS_REQ",
  GET_REPORTS_RES: "GET_REPORTS_RES",
  GET_BYID_REQ: "GET_BYID_REQ",
  GET_BYID_RES: "GET_BYID_RES"
}
