import React, {useEffect, useState} from "react";
import {bindActionCreators} from "redux";
import {CompanyActionCreators} from "../../../../redux/actions/company";
import {connect} from "react-redux";
import {AuthCard} from "../../../../components/_app/AuthCard";
import {SEO} from "../../../../components/global/SEO";
import {CardElement, useElements, useStripe} from "@stripe/react-stripe-js";

const AddBilling = props => {
    const [loading, setLoading] = useState(false);
    const [cardErr, setErr] = useState(null);
    const [expiryDate, setDate] = useState(new Date());
    //const [redirectURL, setRedirect] = useState(false);
    const {actions, history, redirectTo, companyDetails, createSubErr} = props;
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!stripe || !elements) return;
        setLoading(true);
        setErr(null);
        const cardElement = elements.getElement(CardElement);
        const {error, paymentMethod} = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });

        if (error) {
            //console.log('[error]', error);
            setErr(error.message);
            setLoading(false);
        } else {
            await actions.addPaymentMethod(paymentMethod.id);
            await actions.createSubscription();
        }
    };

    useEffect(() => {
        if(redirectTo) {
            window.location = redirectTo;
        } else if(loading) {
           history.push("/app/forms");
        }
    }, [redirectTo]);

    useEffect(() => {
        setLoading(false);
        setErr(createSubErr);
    }, [createSubErr]);

    useEffect(() => {
       props.actions.getDetails();
       let exp = new Date();
       let additionalTime = (7 * 86400000);
       exp = new Date(exp.getTime() + additionalTime);
       setDate(exp);
    },[]);

    return <div className="view auth add-billing">
        <SEO title="Subscription" slug="auth/billing"/>

            <AuthCard>
                <h3>Subscription</h3>
                <p className="content">We want employee satisfaction to be affordable for everyone. That’s why we charge a flat monthly fee of £12 per month no matter how big your team is.</p>
                {companyDetails.freeTrialUsed === 0 && <p className="content">Enjoy a 7 day free trial. Billing will start {expiryDate.toLocaleDateString()}</p>}
                <CardElement
                    options={{
                        style: {
                            base: {
                                fontSize: '16px',
                                color: '#424770',
                                '::placeholder': {
                                    color: '#aab7c4',
                                },
                            },
                            invalid: {
                                color: '#9e2146',
                            },
                        },
                    }}
                />
                <div className="text-center">
                    {cardErr && <span className="form-error">{cardErr}</span>}
                </div>
                <div className="buttons">
                    <button className="btn btn-primary btn-rounded" onClick={handleSubmit} disabled={!stripe || loading}>{loading ? "Subscribing" : "Subscribe"}</button>
                </div>
             </AuthCard>
    </div>
}

const mapStateToProps = (state) => ({
    companyDetails: state.CompanyReducer.companyDetails,
    updateDetailsErr: state.CompanyReducer.updateDetailsErr,
    redirectTo: state.CompanyReducer.redirectTo,
    createSubErr: state.CompanyReducer.createSubErr,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(CompanyActionCreators, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(AddBilling);
