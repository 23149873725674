import React, {Component} from "react";
import {MetricCard} from "../../../components/_app/MetricCard";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {ReportsList} from "../../../components/_app/ReportsList";
import {ReportsActionCreators} from "../../../redux/actions/reports";
import {SEO} from "../../../components/global/SEO";
import SubscriptionWarning from "../../../components/_app/SubscriptionWarning";
import {CompanyActionCreators} from "../../../redux/actions/company";

class Dashboard extends Component {
    state = {loaded: false};

    async componentWillMount() {
        const {actions, companyActions} = this.props;
        await companyActions.getDetails();
        const {history,companyDetails} = this.props;
        if(!companyDetails.name) history.push('/auth/details');
        else if(!companyDetails.integrations || companyDetails.integrations.length === 0 ) history.push('/auth/slack');
        await actions.getAll();
        this.setState({loaded: true})
    }

    render() {
        const {allReports, keyInsights} = this.props;
        document.querySelector('body').scrollTo(0,0);
        window.scrollTo(0,0);
        let responseRate = keyInsights.responseRate && keyInsights.responseRate !== "NaN" ? keyInsights.responseRate : 0;
        let happiness = keyInsights.happiness && keyInsights.happiness !== "NaN" ? keyInsights.happiness : 0;
        return <div className="view app dashboard">
            <SEO title="Dashboard | AMSH" slug="app"/>
            <div className="container">
                <SubscriptionWarning/>
                <div className="app-intro">
                    <h1>Company Dashboard</h1>
                </div>
                <div>
                    <span>Key Insights</span>
                    <hr/>
                    <div className="row mb-5">
                        <div className="col-md-4">
                            <MetricCard metric="Response Rate" value={responseRate} valueExtra="%"/>
                        </div>
                        <div className="col-md-4">
                            <MetricCard metric="Happiness" value={happiness}/>
                        </div>
                        <div className="col-md-4">
                            <MetricCard metric="Social" value={keyInsights[1] || 0}/>
                        </div>
                        <div className="col-md-4">
                            <MetricCard metric="Reward" value={keyInsights[2] || 0}/>
                        </div>
                        <div className="col-md-4">
                            <MetricCard metric="Environment" value={keyInsights[3] || 0}/>
                        </div>
                        <div className="col-md-4">
                            <MetricCard metric="Balance" value={keyInsights[4] || 0}/>
                        </div>
                    </div>
                </div>
                <div>
                    <span>Reports</span>
                    <hr/>
                    <ReportsList reports={allReports}/>
                </div>
            </div>
        </div>
    }
}

const mapStateToProps = (state) => ({
    allReports: state.ReportsReducer.allReports,
    keyInsights: state.ReportsReducer.keyInsights,
    companyDetails: state.CompanyReducer.companyDetails,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(ReportsActionCreators, dispatch),
    companyActions: bindActionCreators(CompanyActionCreators, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
