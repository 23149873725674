let STAGE;
let STRIPE_KEY;
let AUTH_URI;
let SLACK_REDIRECT_URI;

if(window.location.hostname === 'www.aremystaffhappy.com') {
    STAGE = "client-api";
    STRIPE_KEY = "pk_live_51I9wbgJwC2dITbEmbjue9oZ8hnpfCPWUepylecqBIvzGqBEEhwgrJ2EuPjgC3X9uecWw8gae5oQb39r4LwannDew006s8BdcBE";
    AUTH_URI = "auth-sso.aremystaffhappy.com";
    SLACK_REDIRECT_URI = "https://www.aremystaffhappy.com/auth/slack";
} else {
    STAGE = "dev-api";
    STRIPE_KEY = "pk_test_51I9wbgJwC2dITbEmMxWe0HwgjwOmFJU70zI2ia8124gHgYJkpmtG1meW96jQ0zpA3j52UUi0lZaeS3gCtFfhSFXR00ut19RqMg";
    AUTH_URI = "dev-auth-sso.aremystaffhappy.com";
    SLACK_REDIRECT_URI = "https://dev.aremystaffhappy.com/auth/slack";
}

export {STAGE,STRIPE_KEY,AUTH_URI,SLACK_REDIRECT_URI}
