import {CompanyActionTypes} from "../../types";

export const initialState = {
  loading: false,
  updateDetailsErr: null,
  getDetailsErr: null,
  companyDetails: {billing: {}},
  redirectTo: false,
  createSubErr: null,
  paymentStatus: null,
  checkPayErr: null,
  addEmpErr: null,
  addEmpSuccess: false,
};

export const CompanyReducer = (state = initialState, action) => {
  const {type, payload, error} = action;
  switch (type) {
    case CompanyActionTypes.UPDATE_DETAILS_REQ: {
      return {
        ...state,
        loading: true
      }
    }
    case CompanyActionTypes.UPDATE_DETAILS_RES: {
      if (error) {
        return {
          ...state,
          loading: false,
          updateDetailsErr: error
        }
      }
      return {
        ...state,
        loading: false,
        updateDetailsErr: null
      }
    }
    case CompanyActionTypes.GET_DETAILS_REQ: {
      return {
        ...state,
        loading: true
      }
    }
    case CompanyActionTypes.GET_DETAILS_RES: {
      if (error) {
        return {
          ...state,
          loading: false,
          getDetailsErr: error
        }
      }
      return {
        ...state,
        loading: false,
        getDetailsErr: null,
        companyDetails: payload.company || {}
      }
    }
    case CompanyActionTypes.CREATE_SUB_REQ: {
      return {
        ...state,
        loading: true
      }
    }
    case CompanyActionTypes.CREATE_SUB_RES: {
      if (error) {
        return {
          ...state,
          loading: false,
          createSubErr: error
        }
      }
      return {
        ...state,
        loading: false,
        createSubErr: null,
        redirectTo: payload.redirectTo || null
      }
    }
    case CompanyActionTypes.CHECK_PAY_REQ: {
      return {
        ...state,
        loading: true
      }
    }
    case CompanyActionTypes.CHECK_PAY_RES: {
      if (error) {
        return {
          ...state,
          loading: false,
          checkPayErr: error
        }
      }
      return {
        ...state,
        loading: false,
        checkPayErr: null,
        redirectTo: payload.redirectTo || null,
        paymentStatus: payload.status
      }
    }
    case CompanyActionTypes.ADD_EMP_REQ: {
      return {
        ...state,
        loading: true,
        addEmpSuccess: false
      }
    }
    case CompanyActionTypes.ADD_EMP_RES: {
      if (error) {
        return {
          ...state,
          loading: false,
          addEmpErr: error
        }
      }
      return {
        ...state,
        loading: false,
        addEmpErr: null,
        addEmpSuccess: true
      }
    }
    case "LOGOUT": {
      return initialState
    }
    default:
      return state
  }
}
