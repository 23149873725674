import React from 'react';
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
import {Provider} from "react-redux";
import store from "./redux";
import Modal from "react-modal";
import Routing from "./components/global/Routing";
import "./assets/sass/index.scss";
import {AUTH_URI} from "./getStage";
awsconfig.oauth.domain = AUTH_URI
Amplify.configure(awsconfig);
Modal.setAppElement('#root');

function App() {
  return <Provider store={store}>
      <Routing/>
  </Provider>
}

export default App;
