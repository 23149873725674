import React, {useState} from "react";
import {bindActionCreators} from "redux";
import {CompanyActionCreators} from "../../../../redux/actions/company";
import {connect} from "react-redux";
import {AuthCard} from "../../../../components/_app/AuthCard";
import {SEO} from "../../../../components/global/SEO";
import {Redirect} from "react-router-dom";
import {SLACK_REDIRECT_URI} from "../../../../getStage";

const AddSlack = props => {
    const [called, setCalled] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const {actions, location} = props;
    const code = new URLSearchParams(location.search).get("code");

    const addIntegration = async () => {
        await actions.addIntegration({code, integrationType: 2});
        setRedirect(true);
    }

    if(code &&!called) {
        setCalled(true);
        addIntegration();
    }

    return <div className="view auth add-slack">
        <SEO title="Connect with Slack" slug="auth/slack"/>
        <AuthCard>
            <h3>Connect with Slack</h3>
            {code ? <div>
                {redirect ? <Redirect to="/app/forms"/> : <span>Loading...</span>} </div> : <div>
                <p className="content">The final stage is to connect your slack account. This will allow us to automatically send an employee satisfaction survey to your team. </p>
                <p className="content">We will never send anything without your permission!</p>
                <a href={"https://slack.com/oauth/v2/authorize?client_id=465266484597.1370048727280&scope=chat:write,chat:write.customize,team:read,users:read,users:read.email,groups:write,mpim:write,im:write&user_scope=admin,users:read,users:read.email&redirect_uri="+SLACK_REDIRECT_URI}><img alt="Add to Slack" height="40" width="139" src="https://platform.slack-edge.com/img/add_to_slack.png" srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x" /></a>
            </div>}
            </AuthCard>
    </div>
}

const mapStateToProps = (state) => ({
    updateDetailsErr: state.CompanyReducer.updateDetailsErr
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(CompanyActionCreators, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(AddSlack);
