import React, {Component} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {SEO} from "../../../components/global/SEO";
import {CompanyActionCreators} from "../../../redux/actions/company";
import {TitleWithHR} from "../../../components/_app/TitleWithHR";
import {EmployeeList} from "../../../components/_app/EmployeeList";
import NewEmployeeForm from "../../../components/_app/NewEmployeeForm";

class Employees extends Component {

    async componentWillMount() {
        const {actions} = this.props;
        await actions.getDetails();
    }

    render() {
        const {companyDetails} = this.props;
        return <div className="view employees">
            <SEO title="Staff Management" slug="app/staff"/>
            <div className="container">
                <TitleWithHR title="Your Staff Members"/>
                <div className="amsh-card">
                    <EmployeeList employees={companyDetails.employees || []} integrations={companyDetails.integrations}/>
                </div>
                <TitleWithHR title="Add New Staff"/>
                <div className="amsh-card">
                    <p>Staff members added in this form will only be sent surveys via Email.</p>
                    <NewEmployeeForm/>
                </div>
            </div>
        </div>
    }
}

const mapStateToProps = (state) => ({
    companyDetails: state.CompanyReducer.companyDetails
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(CompanyActionCreators, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Employees);
