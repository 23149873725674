import React, {useEffect, useState} from "react";
import {bindActionCreators} from "redux";
import {BHGButton} from "@bytehogs/design-system";
import {CompanyActionCreators} from "../../redux/actions/company";
import {connect} from "react-redux";

const startingState = JSON.stringify([0,1,2].map(i => ({email:"", name: ""})));

const NewEmployeeForm = props => {
    const [employees, setEmployees] = useState(JSON.parse(startingState));
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleChange = (e,i,value) => {
        let allEmployees = [...employees];
        allEmployees[i][value] = e.target.value;
        setEmployees(allEmployees);
    }

    const handleSubmit = async () => {
        setError(null);
        setLoading(true);
        let cleansedEmps = [];
        employees.forEach(x => (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i.test(x.email)) && cleansedEmps.push(x));
        if(cleansedEmps.length > 0) {
            try {
                await props.actions.addEmployees(cleansedEmps);

            } catch(e) {
                setError(e.message);
                setLoading(false);
            }
        }
    }

    useEffect(() => {
        setError(props.addEmpErr);
        setLoading(false);
    }, [props.addEmpErr])

    useEffect(() => {
        if(props.addEmpSuccess && loading) {
            setLoading(false);
            setEmployees(JSON.parse(startingState));
            props.actions.getDetails();
        }
    }, [props.addEmpSuccess])

    return <div className="new-employee-form">
        {employees.map((x,i) => {
            return <div className="row">
                <div className="col-md-5">
                    <input className="form-control" placeholder="Name" value={x.name} onChange={e => handleChange(e,i,'name')}/>
                </div>
                <div className="col-md-7">
                    <input className="form-control" placeholder="Email" value={x.email} onChange={e => handleChange(e,i,'email')}/>
                </div>
            </div>
        })}
        {error && <div className="form-error">{error}</div>}
        <BHGButton onClick={handleSubmit} loading={loading}>Add Staff Members</BHGButton>
    </div>
}

const mapStateToProps = (state) => ({
    addEmpErr: state.CompanyReducer.addEmpErr,
    addEmpSuccess: state.CompanyReducer.addEmpSuccess,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(CompanyActionCreators, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(NewEmployeeForm);
