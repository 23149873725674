import React from "react";

export const HomeCard = (props) => {
  const { image, title, content, children } = props;

  return (
    <div className="home-card">
        {image && <div className="image-container">
        <img src={image} />
      </div>}
      <span className="title">{title}</span>
      <p className="content"> {content} </p>
        {children}
    </div>
  );
};

