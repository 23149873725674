import React, {useEffect, useState} from "react";
import {bindActionCreators} from "redux";
import {CompanyActionCreators} from "../../../../redux/actions/company";
import {connect} from "react-redux";
import {AuthCard} from "../../../../components/_app/AuthCard";
import {SEO} from "../../../../components/global/SEO";
import {BHGInput, BHGInputWrapper} from "@bytehogs/design-system";
import ProfileIcon from "../../../../assets/images/amsh-profile.svg";
import WorkIcon from "../../../../assets/images/amsh-work.svg";
import WebIcon from "../../../../assets/images/amsh-web.svg";

const AddDetails = props => {
    let [state, setState] = useState({name: "", comName: "", website: "", errors: {}, loading: false});
    let [errors, setErrors] = useState({})

    const setValue = (key, value) => {
        let newState = {...state}
        newState[key] = value;
        setState(newState);
    }

    const handleSubmit = async e => {
        e.preventDefault();
        const {name, comName,website, loading} = state;
        if(!loading) {
            setErrors({});
            setValue('loading', true);
            const validationErrors = handleValidation();
            if(validationErrors > 0) setValue('loading', false)
            else {
                const {actions} = props
                try {
                    await actions.updateDetails({name: comName, website, userName: name});
                    const {updateDetailsErr, history} = props;
                    if (updateDetailsErr) {
                        setErrors({...errors, join: updateDetailsErr});
                        setValue('loading', false);
                    }
                    else history.push("/auth/integrations");
                } catch (e) {
                    setErrors( {...errors, join: e.message || "Sorry, something went wrong"});
                    setValue('loading', false);
                }
            }
        }
    }

    const handleValidation = (onlyExistingErrors = false) => {
        const {name,comName,website} = state;
        if(onlyExistingErrors && Object.keys(errors).length > 0 || !onlyExistingErrors) {
            let theErrors = {};
            setErrors(theErrors)
            if (!name || name.length < 6) theErrors.name = 'Required';
            if (!comName || comName.length < 6) theErrors.comName = 'Required';
            if (!website || website.length < 6) theErrors.website = 'Required';
            else if (!/^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/i.test(website)) theErrors.website = 'Invalid Website'
            setErrors(theErrors)
            return Object.keys(theErrors).length;
        }
    }

    useEffect(() =>  {
        props.actions.getDetails();
    }, []);

    useEffect(() => {
        const {companyDetails} = props;
        console.log("companyDetails", companyDetails)
        setState({...state, comName: companyDetails.name, website: companyDetails.website, name: companyDetails.currentUser ? companyDetails.currentUser.name : ""});
    }, [props.companyDetails]);

    return <div className="view auth add-details">
        <SEO title="Complete your account" slug="auth/details"/>
        <AuthCard>
            <h3>Complete your account</h3>
            <p className="content">We just need a few more details to set up your account.</p>
            <form onSubmit={handleSubmit}>
                <BHGInputWrapper error={errors.name}>
                    <img src={ProfileIcon} alt="Profile" className="form-icon"/>
                    <BHGInput type="text" error={errors.name} value={state.name} onChange={e => setValue('name', e.target.value)} placeholder="Your Name"/>
                </BHGInputWrapper>
                <BHGInputWrapper error={errors.comName}>
                    <img src={WorkIcon} alt="Work" className="form-icon"/>
                    <BHGInput type="text" error={errors.comName} value={state.comName} onChange={e => setValue('comName', e.target.value)} placeholder="Company Name"/>
                </BHGInputWrapper>
                <BHGInputWrapper error={errors.website}>
                    <img src={WebIcon} alt="Web" className="form-icon"/>
                    <BHGInput type="text" error={errors.website} value={state.website} onChange={e => setValue('website', e.target.value)} placeholder="Website"/>
                </BHGInputWrapper>
                {errors.join && <div className="form-error">
                    {errors.join}
                </div>}
                <div className="buttons">
                    <button className="btn btn-primary btn-rounded" type="submit">{state.loading ? "Saving..." : "Next"}</button>
                </div>
            </form>
        </AuthCard>
    </div>
}

const mapStateToProps = (state) => ({
    companyDetails: state.CompanyReducer.companyDetails,
    updateDetailsErr: state.CompanyReducer.updateDetailsErr
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(CompanyActionCreators, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(AddDetails);
