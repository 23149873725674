import React from "react";
import {Question} from "./Question";

export const QuestionsList = props => {
    const {questions, answers = {}, setAnswer, template = false} = props;

    if(!questions || questions.length === 0) return <div className="text-center">
        <h2>No Questions</h2>
    </div>
    return <div className="questions-list">
        {questions.map((x, i) => <Question key={x.id} index={i} {...x} template={template} answers={answers[x.id]} setAnswer={values => setAnswer(x.id, values)}/>)}
    </div>
};
