import React from "react";

export const Radios = props => {
    const {options, value, setAnswer} = props;

    return <div className="amsh-radios">
        {options.map(x => {
            return <label className="radio">
                <input type="radio" value={x.id} checked={value.includes(x.id)} onChange={e => setAnswer(e.target.value)}/>
                <span>{x.optionText}</span>
            </label>
        })}
    </div>
}
