import React from "react";
import {Link} from "react-router-dom";
import blogPosts from "../../blog.json";
import {SEO} from "../../components/global/SEO";

export const Blog = props =>  {
    document.querySelector('body').scrollTo(0,0);
    window.scrollTo(0,0);
    return <div className="view blog">
        <SEO
            title="Blog | Are My Staff Happy"
            slug="blog"
        />
        <section className="hero">
            <div className="container">
                <h1>
                    Our Blog
                </h1>
                <p> We've written some articles and blog posts to help make your workplace the best place to work.</p>
            </div>
        </section>
        <section className="blog-posts">
            <div className="container">
                <div className="row">
                    {blogPosts.slice(0).reverse().map((blog, index) => {
                        const today = new Date()
                        if(today.getTime() < new Date(blog.date).getTime()) return null
                        //const {minutes, seconds} = regulateReadTime(calculateReadTime(blog.content))
                        return <article className="col-md-6 col-lg-4" key={blog.slug}>
                                <Link to={"/blog/" + blog.category.toLowerCase() + "/" + blog.slug}>
                                    <div className="blog-post">
                                        <img src={blog.image} alt={blog.title}/>
                                        <div className="content">
                                            <h4>{blog.title}</h4>
                                            <p>{blog.description}...</p>
                                            <span>Read More</span>
                                            <div className="blog-footer">
                                                <span>{blog.category}</span>
                                                <span>{new Date(blog.date).toDateString()}</span>
                                                {/*<span>{minutes + "m" + (seconds ? displayDualDigits(seconds, true) + "s" : "")} read</span>*/}
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </article>
                    })}
                </div>
            </div>
        </section>
    </div>
}
