/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cognito_identity_pool_id": "eu-west-2:5d5338a5-a4f2-47b8-893c-b65ed194277c",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_NKav0cRDE",
    "aws_user_pools_web_client_id": "32m5v6uk5ms1qh35s7beehhoga",
    "oauth": {
        "domain": "amsh-prod.auth.eu-west-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://aremystaffhappy.com/auth/redirect/",
        "redirectSignOut": "https://aremystaffhappy.com/logout/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"
};


export default awsmobile;
