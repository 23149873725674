import React from "react";
import { Link } from "react-router-dom";
import thinkingImage from "../../assets/images/amsh-thinking.svg";
import surveyImage from "../../assets/images/amsh-survey.svg";
import analysisImage from "../../assets/images/amsh-analysis.svg";
import slackImage from "../../assets/images/amsh-slack.svg";
import signupImage from "../../assets/images/amsh-signup.svg";
import setupImage from "../../assets/images/amsh-setup.svg";
import gatherImage from "../../assets/images/amsh-gather.svg";
import hiwBG from "../../assets/images/amsh-how-it-works-bg.svg";
import { HomeCard } from "../../components/_marketing/HomeCard";
import { SEO } from "../../components/global/SEO";

export const Home = (props) => {

  return (
    <div className="view home">
      <SEO
        title="Gain powerful insights into your staff happiness for £12 a month"
        slug=""
      />
      <section className="topsec">
        <div className="container">
          <div className="hero">
            <h1>
              Your team is your lifeblood.
              <br /> Understand them better.
            </h1>
            <Link to="/register" className="btn btn-primary btn-rounded">
              Start 14 Day Free Trial
            </Link>
            <span>No credit card required</span>
          </div>
          <div className="row">
            <div className="col-md-6">
              <h2>
                Stop Guessing,
                <br />
                Start Asking
              </h2>
              <p className="create">
                Create an employee satisfaction survey in less than ten minutes
                and start making more informed business decisions.
              </p>
            </div>
            <div className="col-md-6">
              <img className="thinking" src={thinkingImage} />
            </div>
          </div>
          <div className="better-your-business" id="features">
            <h3>Better Your Business</h3>
            <div className="better">
              <p>
                Gain deep insights into staff happiness, identify your teams’
                strengths and weaknesses and gain direct feedback on how company
                culture and processes can be improved.
              </p>
            </div>
            <div className="row">
              <div className="col-md-4">
                <HomeCard
                  image={surveyImage}
                  title="Ready made surveys"
                  content="Our employee satisfaction surveys are written by experts
                    and ready to go."
                />
              </div>
              <div className="col-md-4">
                <HomeCard
                  image={analysisImage}
                  title="Deep insights"
                  content="We collate your staff feedback to give you real time insights."
                />
              </div>
              <div className="col-md-4">
                <HomeCard
                  image={slackImage}
                  title="Powerful Integration"
                  content="Be where your team communicates with our Slack integration."
                />
              </div>
            </div>

            <p>
              Don’t leave your business to chance. Make informed decisions by
              gathering real and direct feedback from your team today.
            </p>
            <Link to="/register" className="btn btn-primary btn-rounded">
              Start 14 Day Free Trial
            </Link>
          </div>
        </div>
      </section>
      <section className="bottomsec" id="pricing">
        <div className="container">
          <div className="pricing">
            <h2>We do pricing differently</h2>
            <p>
              One flat monthly fee no matter how big your team is. We believe
              employee satisfaction should be affordable whether your team is
              big or small.
            </p>

            <Link to="/register" className="btn btn-secondary">
             {/* Join now for £12 per month*/}
              Start 14 Day Free Trial
            </Link>
            <p>No credit card required</p>
          </div>
        </div>
      </section>
      <section className="thirdsec" id="hiw">
        <div className="works">
          <img src={hiwBG} className="hiw-bg" />
          <div className="container">
            <h2>How It Works</h2>
            <p>
              There's nothing fancy about what we do. We just make it easy to
              ask your staff a series of questions to assess happiness and
              workload, giving you the feedback you need to make meaningful
              business decisions.
            </p>
            <Link to="/register" className="btn btn-secondary">
              Let's get started
            </Link>{" "}
          </div>
        </div>
        <div className="container">
          <div className="row img-left">
            <div className="col-md-6">
              <img className="signup" src={signupImage} />
            </div>
            <div className="col-md-6">
              <h2>Sign Up</h2>
              <p className="caption">
                Create your company account and connect Slack in order to
                connect your entire team with our service. With our single flat
                fee structure, you will be charged the same amount no matter how
                big your team grows.
              </p>
            </div>
          </div>

          <div className="row img-right">
            <div className="col-md-6 order-1 order-md-0">
              <h2>Set Up</h2>
              <p className="caption">
                Our forms are premade and ready to send, just schedule when you
                want your reports to be sent and they will be good to go. You
                can also personalise your forms by adding in additional feedback
                questions.
              </p>
            </div>
            <div className="col-md-6 order-0">
              <img className="signup" src={setupImage} />
            </div>
          </div>
          <div className="row img-left">
            <div className="col-md-6">
              <img className="signup" src={gatherImage} />
            </div>
            <div className="col-md-6">
              <h2>Gather Data</h2>
              <p className="caption">
                We automatically categorise feedback to give you instant
                insights into employee wellbeing. Our tools make it easy to spot
                trends to see respondents answers change over time.
              </p>
            </div>
          </div>
        </div>
        <div className="journey">
          <div className="container">
            <h2>Start Your Journey Today</h2>
            <p>
              Don't leave your company's success to chance. Start listening to
              your team today.
            </p>
            <Link to="/register" className="btn btn-primary btn-rounded">
              Start 14 Day Free Trial
            </Link>
            <span>No credit card required</span>
          </div>
        </div>
      </section>
    </div>
  );
};
