import React, {useEffect, useState} from "react";
import {bindActionCreators} from "redux";
import {CompanyActionCreators} from "../../../../redux/actions/company";
import {connect} from "react-redux";
import {AuthCard} from "../../../../components/_app/AuthCard";
import {SEO} from "../../../../components/global/SEO";
import {CardElement, useElements, useStripe} from "@stripe/react-stripe-js";
import {Link} from "react-router-dom";

const ConfirmBilling = props => {
    const [loading, setLoading] = useState(true)
    const {actions, paymentStatus, redirectTo} = props;

    useEffect(() => {
        actions.checkPaymentStatus()
            .finally(() => {
                setLoading(false)
            })
    }, []);

    const renderStatusMessage = () => {
        if(loading) {
            return <div>
                Loading...
            </div>
        } else if (paymentStatus === 'active') {
            return <div>
                <p>Your payment has gone through!</p>
                <Link className="btn btn-primary btn-rounded" to="/app/forms">Proceed</Link>
            </div>
        } else if (paymentStatus === 'canceled' || !paymentStatus) {
            return <div>
                <p>You don't have a subscription</p>
                <Link className="btn btn-primary btn-rounded" to="/auth/billing">Subscribe Now</Link>
            </div>
        } else if (paymentStatus === 'requires_action') {
            return <div>
                <p>We just need you to authorise your payment</p>
                <a className="btn btn-primary btn-rounded" href={redirectTo}>Authorise Now</a>
            </div>
        } else if (paymentStatus === 'requires_payment_method') {
            return <div>
                <p>We can't use the payment method on record</p>
                <Link className="btn btn-primary btn-rounded" to="/auth/billing">Add Payment</Link>
            </div>
        }
    }

    return <div className="view auth add-billing">
        <SEO title="Confirming" slug="auth/billing/confirm"/>

            <AuthCard>
                <h3>Confirming Payment</h3>
                {renderStatusMessage()}
             </AuthCard>
    </div>
}

const mapStateToProps = (state) => ({
    updateDetailsErr: state.CompanyReducer.updateDetailsErr,
    redirectTo: state.CompanyReducer.redirectTo,
    paymentStatus: state.CompanyReducer.paymentStatus,
    checkPayErr: state.CompanyReducer.checkPayErr,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(CompanyActionCreators, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmBilling);
