import React, {Component} from "react"
import DayPickerInput from "react-day-picker/DayPickerInput";
import MomentLocaleUtils, {
    formatDate,
    parseDate,
} from 'react-day-picker/moment';

export default class DatePicker extends Component {
    state = {
        open: false,
        year: this.props.date
    }

    handleYearChange = (year) => {
        this.setState({year, open: false});
    }
    render() {
        let disabledDays = this.props.disabled ? {
            daysOfWeek: [0,1,2,3,4,5,6,7],
        } : {
            before: this.props.minDate,
            after: this.props.maxDate,
        }
        return <div>
            <DayPickerInput
                format="DD/MM/YYYY"
                formatDate={formatDate}
                placeholder={formatDate(this.props.maxDate, "DD/MM/YYYY")}
                value={this.props.date}
                dayPickerProps={{
                    disabledDays,
                    selectedDays: this.props.date,
                    month: this.state.year
                }}
                inputProps={{
                    readOnly: true,
                    className: "form-control"
                }}
                onDayChange={this.props.change}

            />
        </div>
    }
}
