import {combineReducers} from 'redux';
import { reducer as formReducer } from 'redux-form';
import {AuthReducer} from "./auth";
import {CompanyReducer} from "./company";
import {FormsReducer} from "./forms";
import {ReportsReducer} from "./reports";

const reducers = combineReducers({
    form: formReducer,
    AuthReducer,
    CompanyReducer,
    FormsReducer,
    ReportsReducer
});

export default reducers;
