import React, {Component} from "react";
import {bindActionCreators} from "redux"
import {connect} from "react-redux";
import {SEO} from "../../../components/global/SEO";
import {FormsActionCreators} from "../../../redux/actions/forms";
import {QuestionsList} from "../../../components/_app/QuestionsList";
import {TitleWithHR} from "../../../components/_app/TitleWithHR";
import DatePicker from "../../../components/_app/DatePicker";
import { TimePicker } from 'antd';
import SubscriptionWarning from "../../../components/_app/SubscriptionWarning";
import moment from "moment-timezone";
import timezones from "../../../timezones.json";

class ConfigureForm extends Component {
    state = {loading: false, ready: false, errors: {}, sendEvery: 'month', nextSendDate: moment.utc().tz('Europe/London'), timezone:'Europe/London'};

    async componentDidMount() {
        const {actions} = this.props;
        await actions.getTemplateQuestions();
        const {getQuestionsErr, formTemplate} = this.props;
        let ready = false;
        let sendEvery = 'month';
        let nextSendDate = moment().tz(formTemplate.timezone || 'Europe/London');
        if(!getQuestionsErr) {
            ready = true;
            sendEvery = formTemplate.sendEvery;
            if(formTemplate.nextSendDate) nextSendDate = moment(formTemplate.nextSendDate).tz(formTemplate.timezone);
        }
        this.setState({loading: false, ready, sendEvery, nextSendDate, timezone: formTemplate.timezone});
    }


    updateTemplateSettings = async () => {
        const {sendEvery, nextSendDate, timezone, loading} = this.state;
        if(!loading) {
            const {actions, formTemplate} = this.props;
            this.setState({loading: true});
            try {
                await actions.updateTemplate({templateId: formTemplate.id, sendEvery, nextSendDate, timezone});
                await actions.getTemplateQuestions();
                this.setState({nextSendDate: moment(this.props.formTemplate.nextSendDate).tz(this.props.formTemplate.timezone), timezone: this.props.formTemplate.timezone })
            } catch(e) {
                console.log("e", e);
            } finally {
                this.setState({loading: false})
            }
        }
    }

    handleDateChange = e => {
        const {nextSendDate} = this.state;
        const {formTemplate} = this.props;
        const theDate = new Date(nextSendDate);
        e.setUTCHours(theDate.getUTCHours());
        e.setUTCMinutes(theDate.getUTCMinutes());
        this.setState({nextSendDate: moment(e).tz(formTemplate.timezone)})
    }

    handleTimeChange = nextSendDate => {
       /* const {nextSendDate} = this.state;
        const hours = time.split(':')[0];
        const minutes = time.split(':')[1];
        let theNextDate = new Date(nextSendDate);
        theNextDate.setUTCHours(hours);
        theNextDate.setUTCMinutes(minutes);*/
        this.setState({nextSendDate});
    }

    handleTimezoneChange = e => {
       /* const {nextSendDate} = this.state;
        const hours = time.split(':')[0];
        const minutes = time.split(':')[1];
        let theNextDate = new Date(nextSendDate);
        theNextDate.setUTCHours(hours);
        theNextDate.setUTCMinutes(minutes);*/
        const {nextSendDate} = this.state;
        const timezone = e.target.value;
        this.setState({nextSendDate: moment(nextSendDate).tz(timezone), timezone});
    }

    isSaveDisabled = () => {
        const {formTemplate} = this.props;
        const {timezone, nextSendDate, sendEvery} = this.state;
        let isDisabled = false;
        if(formTemplate.timezone === timezone && moment(formTemplate.nextSendDate).tz(formTemplate.timezone).toString() === nextSendDate.toString() && formTemplate.sendEvery === sendEvery) isDisabled = true;
        return isDisabled;
    }

    render() {
        const {ready, sendEvery, nextSendDate, timezone, loading} = this.state;
        const {formTemplate} = this.props;

        return <div className="view configure-form">
            <SEO title="Configure your form" slug="app/forms"/>
            <div className="container mb-5">
                <SubscriptionWarning/>
                <div className="title-bar">
                    <h3>Configure Your Form</h3>
                    <p>Here you can modify when your questions will be sent out to your team. We use a pre-made set of questions designed to assess your companies on various metrics such as happiness. {/*You can also add your own questions to gain additional feedback from your team!*/}</p>
                </div>
                {!ready ? <div className="amsh-card">
                    <div className="text-center">
                        Loading...
                    </div>
                </div> : <div className={"form-container"}>

                    <TitleWithHR title="When should your report be sent"/>
                    <div className="report-details">
                        <label>When should the next form be sent?</label>
                        <div className="date-wrapper">
                            <div className="dates">
                                <DatePicker
                                    date={new Date(nextSendDate)}
                                    minDate={new Date()}
                                    change={this.handleDateChange}
                                />
                            </div>
                            <div className="times">
                                <TimePicker
                                    value={nextSendDate}
                                    inputReadOnly
                                    allowClear={false}
                                    minuteStep={15}
                                    onChange={(t,timeString) => this.handleTimeChange(t)}
                                    showNow={false}
                                    format="HH:mm"/>
                               {/* <span>@ 4:00pm (GMT)</span>*/}
                            </div>
                            <div className="timezone">
                                <select value={timezone} className="form-control" onChange={this.handleTimezoneChange}>
                                    {Object.keys(timezones).map(tz => <option key={tz} value={timezones[tz]}>{tz}</option>)}
                                </select>
                            </div>
                        </div>
                        {/*<div className="timezone">
                            <label>Timezone</label>
                            <select value={timezone} className="form-control" onChange={e => this.setState({timezone: e.target.value})}>
                                {Object.keys(timezones).map(tz => <option key={tz} value={timezones[tz]}>{tz}</option>)}
                            </select>
                        </div>*/}
                        <label>This form should be sent</label>
                        <div className="sent-container">
                            <select value={sendEvery} onChange={e => this.setState({sendEvery: e.target.value})} className="form-control">
                                <option value='week'>Every Week</option>
                                <option value='month'>Every Month</option>
                            </select>
                        </div>
                        <div className="buttons">
                            <button className="btn btn-primary btn-rounded" onClick={this.updateTemplateSettings} disabled={this.isSaveDisabled()}>{loading ? "Saving" : "Save"}</button>
                        </div>
                    </div>
                    {/*<h3>{heading}</h3>*/}
                    <div className="main-body">
                        <TitleWithHR title="Questions"/>
                        <QuestionsList questions={formTemplate.questions} template/>
                    </div>
                </div>}
            </div>
        </div>
    }
}

const mapStateToProps = (state) => ({
    getQuestionsErr: state.FormsReducer.getQuestionsErr,
    formTemplate: state.FormsReducer.formTemplate,
    respondErr: state.FormsReducer.respondErr,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(FormsActionCreators, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ConfigureForm);
