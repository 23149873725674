import React from "react";
import {ReportItem} from "./ReportItem";

export const ReportsList = props => {
    const {reports} = props;
    if(reports.length === 0) return <div>
        <h4>No Reports Yet</h4>
    </div>
    return <div className="reports-list">
        <div className="row">
            {reports.map(r => <div className="col-12" key={r.id} ><ReportItem {...r}/></div>)}
        </div>
    </div>
}
