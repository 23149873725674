import React from "react";
import {SEO} from "../../components/global/SEO";
import {Link} from "react-router-dom";
import {HomeCard} from "../../components/_marketing/HomeCard";

export const Roadmap = props =>  {
    document.querySelector('body').scrollTo(0,0);
    window.scrollTo(0,0);
    return <div className="view roadmap">
        <SEO
            title="Our Product Roadmap | Are My Staff Happy"
            slug="roadmap"
        />
        <section className="hero">
            <div className="container">
                <h1>
                    Product Roadmap
                </h1>
                <p>We're constantly working on improving our little tool. We want to do better, and don't worry - we'll never increase our pricing.
                    <br/><br/>
                    If you have a feature request you can send it over to us at support@aremystaffhappy.com - if we build it we'll even give you 6 months free access.</p>
            </div>
        </section>
        <section className="map">
            <div className="container">
                <HomeCard title="January 2021 - Launched &nbsp;🎉" content={<p>- Set timezone and custom time to send reports<br/>- Send surveys via Email</p>}/>
                <HomeCard title="February 2021" content={<p>- Invite more managers<br/>- Choose multiple managers to receive reports<br/>- Team management</p>}/>
                <HomeCard title="March 2021" content={<p>- Ask custom questions<br/>- Team specific surveys</p>}/>
                <HomeCard title="April 2021" content={<p>- Microsoft Teams integration<br/>- Survey question cycling</p>}/>
            </div>
        </section>
        <section className="cta-container">
            <div className="container">
                <h3>Can't wait to try us out?</h3>
                <p>We can't wait either - let's get started!</p>
                <Link to="register" className="btn btn-primary btn-rounded">Start 14 day free trial</Link>
                <span className="no-cc-req">No credit card required</span>
            </div>
        </section>
    </div>
}
