import React from "react";
import { Link, withRouter } from "react-router-dom";
import { Logo } from "../global/Logo";

const AppHeader = (props) => {
  return (
    <header className={"app-header"}>
      <div className="container">
        <div className="left">
          <div className="brand">
            <Link to="/app" className="desktop">
              <Logo />
            </Link>
            <Link to="/app" className="mobile">
              <Logo mobile />
            </Link>
          </div>
        </div>
        <nav>
          <Link to="/app/">Dashboard</Link>
          <Link to="/app/forms">Configure Form</Link>
          <Link to="/app/staff">Manage Staff</Link>
          <Link to="/app/settings">Settings</Link>
          <Link to="/logout">Sign Out</Link>
        </nav>

      </div>
    </header>
  );
};

export default withRouter(AppHeader);
