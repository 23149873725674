import {CompanyActionTypes} from "../../types";
import {BytehogsAPI} from "../BytehogsAPI";

const SERVICE = 'amsh-be';

export const CompanyActionCreators = {
    addIntegration: (data  = {}) => async (dispatch) => {
        dispatch({
            type: CompanyActionTypes.ADD_INTEGRATION_REQ
        });
        try {
            const sessionToken = await localStorage.getItem('sToken');
            let payload = {...data, sessionToken, operation: "addIntegration"};
            await BytehogsAPI(SERVICE, 'integrations', payload);
            dispatch({
                type: CompanyActionTypes.ADD_INTEGRATION_RES,
                error: null
            })
        } catch (e) {
            dispatch({
                type: CompanyActionTypes.ADD_INTEGRATION_RES,
                error: e.data ? e.data.message || e.message :  "Something went wrong"
            })
        }
    },
    updateDetails: data => async (dispatch) => {
        dispatch({
            type: CompanyActionTypes.UPDATE_DETAILS_REQ
        });
        try {
            const sessionToken = await localStorage.getItem('sToken')
            let payload = {...data, sessionToken, operation: "updateDetails"}
            const response = await BytehogsAPI(SERVICE, 'company', payload)
            dispatch({
                type: CompanyActionTypes.UPDATE_DETAILS_RES,
                payload: response.data
            })
        } catch (e) {
            console.log("e", e)
            dispatch({
                type: CompanyActionTypes.UPDATE_DETAILS_RES,
                error: e.data ? e.data.message || e.message :  "Something went wrong"
            })
        }
    },
    getDetails: () => async (dispatch) => {
        dispatch({
            type: CompanyActionTypes.GET_DETAILS_REQ
        });
        try {
            const sessionToken = await localStorage.getItem('sToken')
            let payload = {sessionToken, operation: "getDetails"}
            const response = await BytehogsAPI(SERVICE, 'company', payload)
            dispatch({
                type: CompanyActionTypes.GET_DETAILS_RES,
                payload: response.data
            })
        } catch (e) {
            console.log("e", e)
            dispatch({
                type: CompanyActionTypes.GET_DETAILS_RES,
                error: e.data ? e.data.message || e.message :  "Something went wrong"
            })
        }
    },
    addPaymentMethod: (paymentMethod) => async (dispatch) => {
        dispatch({
            type: CompanyActionTypes.ADD_PAYM_REQ
        });
        try {
            const sessionToken = await localStorage.getItem('sToken');
            let payload = {paymentMethod, sessionToken, operation: "addPaymentMethod"};
            await BytehogsAPI(SERVICE, 'billing', payload);
            dispatch({
                type: CompanyActionTypes.ADD_PAYM_RES,
                error: null
            })
        } catch (e) {
            dispatch({
                type: CompanyActionTypes.ADD_PAYM_RES,
                error: e.data ? e.data.message || e.message :  "Something went wrong"
            })
        }
    },
    createSubscription: () => async (dispatch) => {
        dispatch({
            type: CompanyActionTypes.CREATE_SUB_REQ
        });
        try {
            const sessionToken = await localStorage.getItem('sToken');
            let payload = {sessionToken, operation: "createSubscription"};
            const response = await BytehogsAPI(SERVICE, 'billing', payload);
            dispatch({
                type: CompanyActionTypes.CREATE_SUB_RES,
                error: null,
                payload: response.data
            })
        } catch (e) {
            dispatch({
                type: CompanyActionTypes.CREATE_SUB_RES,
                error: e.data ? e.data.message || e.message :  "Something went wrong"
            })
        }
    },
    cancelSubscription: () => async (dispatch) => {
        dispatch({
            type: CompanyActionTypes.CANCEL_SUB_REQ
        });
        try {
            const sessionToken = await localStorage.getItem('sToken');
            let payload = {sessionToken, operation: "cancelSubscription"};
            await BytehogsAPI(SERVICE, 'billing', payload);
            dispatch({
                type: CompanyActionTypes.CANCEL_SUB_RES,
                error: null
            })
        } catch (e) {
            dispatch({
                type: CompanyActionTypes.CANCEL_SUB_RES,
                error: e.data ? e.data.message || e.message :  "Something went wrong"
            })
        }
    },
    checkPaymentStatus: () => async (dispatch) => {
        dispatch({
            type: CompanyActionTypes.CHECK_PAY_REQ
        });
        try {
            const sessionToken = await localStorage.getItem('sToken');
            let payload = {sessionToken, operation: "checkPaymentStatus"};
            const response = await BytehogsAPI(SERVICE, 'billing', payload);
            dispatch({
                type: CompanyActionTypes.CHECK_PAY_RES,
                payload: response.data,
                error: null
            })
        } catch (e) {
            dispatch({
                type: CompanyActionTypes.CHECK_PAY_RES,
                error: e.data ? e.data.message || e.message :  "Something went wrong"
            })
        }
    },
    generateStripeSession: () => async (dispatch) => {
        try {
            const sessionToken = await localStorage.getItem('sToken');
            let payload = {sessionToken, operation: "generateStripeSession"};
            const response = await BytehogsAPI(SERVICE, 'billing', payload);
            window.location.href = response.data.sessionUrl;
            /*dispatch({
                type: CompanyActionTypes.CHECK_PAY_RES,
                payload: response.data,
                error: null
            })*/
        } catch (e) {
            dispatch({
                type: CompanyActionTypes.CHECK_PAY_RES,
                error: e.data ? e.data.message || e.message :  "Something went wrong"
            })
        }
    },
    addEmployees: employees => async (dispatch) => {
        dispatch({
            type: CompanyActionTypes.ADD_EMP_REQ
        });
        try {
            const sessionToken = await localStorage.getItem('sToken');
            let payload = {employees, sessionToken, operation: "addNew"};
            await BytehogsAPI(SERVICE, 'employee', payload);
            dispatch({
                type: CompanyActionTypes.ADD_EMP_RES,
                error: null
            })
        } catch (e) {
            dispatch({
                type: CompanyActionTypes.ADD_EMP_RES,
                error: e.data ? e.data.message || e.message :  "Something went wrong"
            })
        }
    },
}
