import React, { Component } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import { bindActionCreators } from "redux";
import { AuthActionCreators } from "../../redux/actions/auth";
import { connect } from "react-redux";
import ReactGA from "react-ga";
import Login from "../../pages/_app/auth/Login";
import Register from "../../pages/_app/auth/Register";
import Logout from "../../pages/_app/auth/Logout";
import { Toast } from "./Toast";
import Dashboard from "../../pages/_app/dashboard/Dashboard";
import { Home } from "../../pages/_marketing/Home";
import MHeader from "../_marketing/MHeader";
import AuthHeader from "../_app/AuthHeader";
import AddDetails from "../../pages/_app/auth/wizard/AddDetails";
import AuthRedirect from "../../pages/_app/auth/AuthRedirect";
import AddSlack from "../../pages/_app/auth/wizard/AddSlack";
import AddBilling from "../../pages/_app/auth/wizard/AddBilling";
import AppHeader from "../_app/AppHeader";
import SurveyResponse from "../../pages/_app/survey/SurveyResponse";
import ReportDetails from "../../pages/_app/dashboard/ReportDetails";
import ConfigureForm from "../../pages/_app/forms/ConfigureForm";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import {STRIPE_KEY} from "../../getStage";
import AnalyticsTracker from "./AnalyticsTracker";
import Settings from "../../pages/_app/company/Settings";
import ConfirmBilling from "../../pages/_app/auth/wizard/ConfirmBilling";
import {Privacy} from "../../pages/_marketing/Privacy";
import {Roadmap} from "../../pages/_marketing/Roadmap";
import {Integrations} from "../../pages/_marketing/Integrations";
import {Footer} from "../_marketing/Footer";
import Employees from "../../pages/_app/company/Employees";
import WizIntegrations from "../../pages/_app/auth/wizard/WizIntegrations";
import AddEmail from "../../pages/_app/auth/wizard/AddEmail";
import {Blog} from "../../pages/_marketing/Blog";
import {BlogPost} from "../../pages/_marketing/BlogPost";
import {Pricing} from "../../pages/_marketing/Pricing";
import {AnonStart} from "../../pages/_marketing/anonymous-feedback/AnonStart";
const stripePromise = loadStripe(STRIPE_KEY);

const ProtectedRoute = ({ isVerified, li, ...props }) => {
  return isVerified ? (
    <Route {...props} />
  ) : (
    <Redirect to={li ? "/app" : "/login?return_url=" + props.path} />
  );
};

class Routing extends Component {
  constructor(props) {
    super(props);

    ReactGA.initialize('UA-178510820-3', {
      debug: false,
      titleCase: false
    });

    this.state = { canRender: false };
  }

  componentWillMount() {
    const { actions } = this.props;
    actions.checkToken().finally(() => this.setState({ canRender: true }));
  }

  render() {
    const { canRender } = this.state;
    const { isAuthed } = this.props;
    return (
      <BrowserRouter>
        <ToastProvider components={{ Toast: Toast }}>
          <Switch>
            <Route path="/auth">
              {canRender ? <>
                <AuthHeader />
                <Switch>
                  <ProtectedRoute isVerified={!isAuthed} li exact path="/auth/login" component={AnalyticsTracker(Login)}/>
                  <ProtectedRoute isVerified={!isAuthed} li exact path="/auth/register" component={AnalyticsTracker(Register)}/>
                  <Route exact path="/auth/redirect" component={AuthRedirect}/>
                  <ProtectedRoute isVerified={isAuthed} exact path="/auth/details" component={AnalyticsTracker(AddDetails)}/>
                  <ProtectedRoute isVerified={isAuthed} exact path="/auth/integrations" component={AnalyticsTracker(WizIntegrations)}/>
                  <ProtectedRoute isVerified={isAuthed} exact path="/auth/slack" component={AnalyticsTracker(AddSlack)}/>
                  <ProtectedRoute isVerified={isAuthed} exact path="/auth/email" component={AnalyticsTracker(AddEmail)}/>
                  <Elements stripe={stripePromise}>
                    <ProtectedRoute isVerified={isAuthed} exact path="/auth/billing" component={AnalyticsTracker(AddBilling)}/>
                    <ProtectedRoute isVerified={isAuthed} exact path="/auth/billing/confirm" component={AnalyticsTracker(ConfirmBilling)}/>
                  </Elements>
                  <Redirect to="/app"/>
                </Switch>
              </> : <div className="container">Loading...</div>}
            </Route>
            <Route path="/app">
              <AppHeader />
              {canRender ? <>
                <Switch>
                  <ProtectedRoute isVerified={isAuthed} exact path="/app" component={AnalyticsTracker(Dashboard)}/>
                  <ProtectedRoute isVerified={isAuthed} exact path="/app/reports/:id" component={AnalyticsTracker(ReportDetails)}/>
                  <ProtectedRoute isVerified={isAuthed} exact path="/app/forms" component={AnalyticsTracker(ConfigureForm)}/>
                  <ProtectedRoute isVerified={isAuthed} exact path="/app/settings" component={AnalyticsTracker(Settings)}/>
                  <ProtectedRoute isVerified={isAuthed} exact path="/app/staff" component={AnalyticsTracker(Employees)}/>
                  <Redirect to="/app"/>
                </Switch>
              </> : <div className="container">Loading...</div>}
            </Route>
            <Route path="/survey/:token">
              <AuthHeader />
              <Switch>
                <Route path="/survey/:token" exact component={AnalyticsTracker(SurveyResponse)}/>
                <Redirect to="/app"/>
              </Switch>
            </Route>
            <Route path="/">
              <MHeader />
              <Switch>
                <Route path="/" exact component={AnalyticsTracker(Home)}/>
                <Route path="/pricing" exact component={AnalyticsTracker(Pricing)}/>
                <Route path="/integrations" exact component={AnalyticsTracker(Integrations)}/>
                <Route path="/roadmap" exact component={AnalyticsTracker(Roadmap)}/>
                <Route path="/blog" exact component={AnalyticsTracker(Blog)}/>
                <Route path="/blog/:category/:slug" exact component={AnalyticsTracker(BlogPost)}/>
                <Route path="/anonymous-feedback" exact component={AnalyticsTracker(AnonStart)}/>
                <Route path="/privacy" exact component={AnalyticsTracker(Privacy)}/>
                <Redirect from="/login" to="/auth/login" />
                <Redirect from="/register" to="/auth/register" />
                <ProtectedRoute isVerified={isAuthed} exact path="/logout" component={AnalyticsTracker(Logout)}/>
                <Redirect to="/"/>
              </Switch>
              <Footer/>
            </Route>
          </Switch>
        </ToastProvider>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthed: state.AuthReducer.isAuthed,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(AuthActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Routing);
