import React from "react";
import {EmployeeListItem} from "./EmployeeListItem";

export const EmployeeList = props => {
    const {employees, integrations} = props;

    const getIntegrationName = id => {
        const integration = integrations.find(x => x.integrationType === id) || {};
        return integration.integrationName
    }

    return <div className="employee-list">
        {employees.length === 0 ? <div>Looks like you don't have any staff members yet</div> : <div className="row">
            <div className="col-md-2">
                <h5>Name</h5>
            </div>
            <div className="col-md-3">
                <h5>Email</h5>
            </div>
            <div className="col-md-3">
                <h5>Integration</h5>
            </div>
        </div>}
        {employees.map(emp => <EmployeeListItem key={emp.id} integration={getIntegrationName(emp.defaultIntegrationId)} {...emp}/>)}
    </div>
}
