import React from "react";
import blogPosts from "../../blog"
import {Link} from "react-router-dom";
import {SEO} from "../../components/global/SEO";

export const BlogPost = props => {
    const {match: {url}} = props
    window.scrollTo(0,0)
    let theUrl = url
    if(theUrl.endsWith("/")) {
        let temp = theUrl.split('')
        temp.splice(temp.length-1, 1)
        theUrl = temp.join('')
    }
    const BLOG = blogPosts.find(x => {if('/blog/' + x.category.toLowerCase() + '/' + x.slug.toLowerCase() === theUrl.toLowerCase()) return x})
    if(!BLOG) return <div>Not found </div>
    /*const {minutes, seconds} = regulateReadTime(calculateReadTime(BLOG.content))*/
    return <div className="view blog-post">
        <SEO
            title={BLOG.title + ' | Are My Staff Happy'}
            description={BLOG.description}
            image={BLOG.image}
            slug={theUrl}
            type="article"
            fullImageUrl/>
        <section className="blog-content">
            <div className="container">
                <div className="blog-post">
                    <h1>{BLOG.title}</h1>

                    <img src={BLOG.image} className="feature-image" alt={BLOG.title}/>
                    <div dangerouslySetInnerHTML={{__html: BLOG.content}} className="raw-content"/>
                    <hr/>
                    <span className="author">Posted by {BLOG.author} on {new Date(BLOG.date).toDateString()}</span><br/>

                    {/*<div className="social-shares">
                        <FBShare
                            url={"https://www.cudacare.com"+BLOG.slug}
                            text='Share'/>
                        <TTShare
                            url={"https://www.cudacare.com"+BLOG.slug}
                            text='Tweet'/>
                    </div>*/}
                </div>
            </div>
        </section>
        <section className="cta-container">
            <div className="container">
                <h3>Can't wait to try us out?</h3>
                <p>We can't wait either - let's get started!</p>
                <Link to="register" className="btn btn-primary btn-rounded">Start 14 day free trial</Link>
                <span className="no-cc-req">No credit card required</span>
            </div>
        </section>
        {/*<LatestBlogPosts activeBlog={BLOG.slug}/>*/}
    </div>
}
