import React, {useEffect, useState} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {CompanyActionCreators} from "../../redux/actions/company";
import {Link} from "react-router-dom";

const SubscriptionWarning = props => {
    const [active, setActive] = useState(true);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const call = async () => {
            await props.actions.getDetails();
            setLoaded(true);
        };
        call();
    },[]);

    useEffect(() => {
        if(loaded) {
            const {companyDetails: {billing: {subscription}}} = props;
            if ((!subscription || (subscription && (subscription.status === 'canceled' || subscription.status === 'incomplete_expired'))) && active) {
                setActive(false);
            } else {
                setActive(true);
            }

        }
    }, [props.companyDetails, loaded]);


    if(active) return null;
    return <div className="subscription-warning">
        <h4>Subscription Required</h4>
        <p>You need an active subscription to continue using our service. Your surveys will not be sent until a new subscription has been added.</p>
        <Link to="/auth/billing" className="btn btn-primary btn-rounded">Subscribe Now</Link>
    </div>
}

const mapStateToProps = (state) => ({
    companyDetails: state.CompanyReducer.companyDetails
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(CompanyActionCreators, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionWarning);
