import React from "react";
import Modal from "react-modal";

const metricDefs = {
    'Social': <p>Your team’s <strong>social happiness score</strong> is based on how much your team enjoys interacting with others in the workplace. A high social happiness score indicates that your team has a strong bond which extends beyond the workplace which contributes to a healthier work environment and lower levels of employee churn.</p>,
    'Reward': <p>The <strong>reward score</strong> determines how rewarding your team finds the work they do. A high reward score indicates your team is motivated and that they enjoy the work they do. Employees performance is often directly correlated to how rewarding employees find their work.</p>,
    'Environment': <p>The <strong>work environment score</strong> is a measure of how close the company's work environment  is to an ideal work environment - where staff feel valued and can freely and openly talk through their issues with colleagues and managers. The best companies are those which create an environment where the staff are empowered to do their best work.</p>,
    'Balance': <p>The <strong>balance score</strong> determines how well your team balances work with their outside life. A low score is indicative of a company where employees are regularly working late and spend a large portion of their free time working. While a poor balance score can lead to short term high productivity it almost inevitably leads to burn out.</p>,
}

export const ParameterModal = props => {
    const {isOpen, close, parameter} = props;

    return <Modal
        isOpen={isOpen}
        onRequestClose={close}
        className={"modal animated parameter"}
        overlayClassName="modal-overlay"
        contentLabel="Parameter Modal">
        <div>
            <h3>{parameter}</h3>
            {metricDefs[parameter]}
            <button className="btn btn-primary btn-rounded" onClick={close}>Great thanks!</button>
        </div>
    </Modal>
}
