import React from "react";
import {Link} from "react-router-dom";

export const Footer = props => {
    return <section className="footer">
        <div className="container">
            <h2>Any questions?</h2>
            <p>Send us your questions to support@aremystaffhappy.com and we will get straight back to you.</p>

            {/*<div className="row">
            <div className="col-md-4">
              <div className="wl-input-group">
                <input
                  type="text"
                  placeholder="First Name"
                  className="form-control"
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="wl-input-group">
                <input
                  type="text"
                  placeholder="Last Name"
                  className="form-control"
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="wl-input-group">
                <input
                  type="text"
                  placeholder="Email"
                  className="form-control"
                />
              </div>
            </div>
            <div className="col-12">
              <textarea
                placeholder="Question"
                rows={4}
                className="form-control"
              />
            </div>
          </div>

          <button className="btn btn-secondary">Submit</button>*/}
            <div className="links">
                <Link to="/privacy" className="legal-link">Privacy Policy</Link>
                <Link to="/roadmap" className="legal-link">Roadmap</Link>
                <Link to="/integrations" className="legal-link">Our Integrations</Link>
                <Link to="/blog" className="legal-link">Our Blog</Link>
            </div>

            <a href="https://bytehogs.com"> <img
                src="https://client-upload.bytehogs.com/bhg/brand/powered_by_bytehogs_white.png"
                className="powered-by-bytehogs "/></a>

        </div>
    </section>
}
