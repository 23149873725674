import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { AuthActionCreators } from "../../../redux/actions/auth";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { SEO } from "../../../components/global/SEO";
import { Auth } from "aws-amplify";
import {SigninCard} from "../../../components/_app/SigninCard";

class Register extends Component {
  state = { email: "", password: "", errors: {}, loading: false };

  setValue = (key, value) => {
    this.setState({ [key]: value });
    this.handleValidation(true);
  };

  handleGoogleLogin = async (e) => {
    try {
      await Auth.federatedSignIn({ provider: "Google" });
      const userData = await Auth.currentAuthenticatedUser();
    } catch (e) {
      console.log("Not signed in", e);
    }
  };

  handleSubmit = async (e) => {
    try {
      e.preventDefault();
      this.setValue('loading', true);
      const {actions, history} = this.props;
      const {email, password} = this.state;
      const errors = this.handleValidation();
      if(errors === 0) {
        await actions.register({email, password});
        const {registerErr} = this.props;
        if(registerErr) this.setState({errors: {join: registerErr}, loading: false});
        else {
          await actions.login({email, password});
          const {loginErr} = this.props;
          if(loginErr) this.setState({errors: {join: loginErr}, loading: false});
          else history.push('/auth/redirect');
        }
      } else {
        this.setValue('loading', false);
      }
    } catch (e) {
      this.setValue('loading', false);
      console.log("Not signed in", e);
    }
  };

  handleValidation = (onlyExistingErrors = false) => {
    const { email, password } = this.state;
    if (
      (onlyExistingErrors && Object.keys(this.state.errors).length > 0) ||
      !onlyExistingErrors
    ) {
      let errors = {};
      this.setState({ errors });
      if (!email) errors.email = "Required";
      else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i.test(email))
        errors.email = "Invalid email";
      if (!password || password.length < 6) errors.password = "Required";
      if (Object.keys(errors).length > 0) this.setState({ errors });
      return Object.keys(errors).length;
    }
  };

  render() {
    const { email, password, errors, loading } = this.state;
    return (
      <div className="view auth login">
        <SEO title="Sign Up | Are My Staff Happy" slug="register" />
        <div className="container">
          <SigninCard
            title="Start 14 Day Free Trial"
            handleGoogleClick={this.handleGoogleLogin}
            register
            handleSubmit={this.handleSubmit}
            email={email}
            password={password}
            errors={errors}
            loading={loading}
            setValue={this.setValue}
            content={
              <>
                <p>
                  Already have an account? <Link to="/login">Sign In</Link>
                </p>
              </>
            }
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  registerErr: state.AuthReducer.registerErr,
  loginErr: state.AuthReducer.loginErr,
  isAuthed: state.AuthReducer.isAuthed,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(AuthActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Register);
